import React, { useEffect } from "react";
import img from "../../../Images/6.png";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  Box,
  AccordionIcon,
  AccordionPanel,
} from "@chakra-ui/react";

import Contact from "../../../components/Contact";
import SmallStrip from "../../../components/SmallStrip";
import ModalContact2 from "../../../components/ModalContact2";
import { Helmet } from "react-helmet";

const Industrial = () => {
  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, []);
	useEffect(() => {
		// Add the GTM script dynamically to the head of the document
		const script = document.createElement('script');
		script.async = true;
		script.src = 'https://www.googletagmanager.com/gtag/js?id=G-4Y7FD8DFE9';
		document.head.appendChild(script);

		script.onload = () => {
			// Execute the inline script once the external script is loaded
			const inlineScript = document.createElement('script');
			inlineScript.type = 'text/javascript';
			inlineScript.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments)}
        gtag('js', new Date());
        gtag('config', 'G-4Y7FD8DFE9');
      `;
			document.head.appendChild(inlineScript);
		};

		return () => {
			// Cleanup scripts on component unmount
			document.head.removeChild(script);
		};
	}, []);

  return (
    <>
			<Helmet>
				<title>Industrial License</title>
				<meta name="description" content="All license here" />
				<script async src={`https://www.googletagmanager.com/gtag/js?id=G-4Y7FD8DFE9`}></script>
				<script>
					{`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments)}
          gtag('js', new Date());
          gtag('config', 'G-4Y7FD8DFE9');
        `}
				</script>
			</Helmet>

      <SmallStrip data="Industrial" data2="Licensing" />

      <div className="container-fluid padding3">
        <div className="row">
          <div className="col-md-6 col-lg-4 col-xl-4">
            <img src={img} alt="error" className="img-fluid rounded" />
          </div>
          <div className="col-md-6 col-lg-8 col-xl-8">
            <p className="heading-text-h1 bold-text color">
              Industrial License in Dubai, UAE
            </p>

						<p className="para-small mt-2">
              Dubai, located in the Middle East, is a city that attracts businesses from around the world due to its strategic location and efficient infrastructure. Dubai has a reputation for being a world-class hub for business and industry, with a strong focus on connecting the East to the West. This has made the city one of the prime industrial business destinations for entrepreneurs who want to set up their business in the UAE. Dubai's infrastructure is awe-inspiring, with modern amenities that are essential for any successful business.
            </p>
						<p className="para-small mt-2">
							The city has some of the most superior trade routes, making it easy to access commercial activities between the UAE and Europe, Asia, and even Africa. This has created a favorable environment for businesses in Dubai, with a diverse range of industries and sectors thriving in the city.If you are interested in starting a company or running a business related to the industrial world in the UAE, an industrial license is a fundamental requirement. In Dubai mainland, this license is issued by the Department of Economic Development (DED). The industrial license is considered to be the official permit for anyone looking to run a production or manufacturing-based business legally in the UAE. Obtaining this license is crucial, as it ensures that you can legally conduct your business activities without any issues.
						</p>
						<p className="para-small mt-2">
							One of the most significant benefits of obtaining an industrial license is that it encompasses not only production but also manufacturing-type activities. This means that the license covers all the processes involved in transforming natural resources or raw materials from the crude or initial stage to a refined or final stage, along with production and manufacturing, industrial aggregation, segregation, and final packaging.

						</p>
            <br></br>

            <ModalContact2 />
          </div>
        </div>
      </div>
      <br></br>

      <div className="container-fluid padding3 ">
        <br></br>
        <div className="shadow">
          <Accordion defaultIndex={[0]} allowMultiple>
            <AccordionItem className="drop-para">
              <h2>
                <AccordionButton>
                  <Box
                    flex="1"
                    textAlign="left"
                    className="bold-text color-text font2"
                  >
                    WHAT IS THE RELEVANCE OF AN INDUSTRIAL LICENSE IN THE UAE?
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4} className="para-small">
                In the UAE, the industrial license is a vital requirement for entrepreneurs who want to establish companies focused on industrial business activities anywhere in the UAE. These activities include manufacturing from imported raw materials, assembling, packaging, transporting, and even exporting finished products.
                At Future Business Solutions (FBS), we understand that obtaining an industrial license can be a complex and time-consuming process. That is why we offer assistance to ensure an easy and hassle-free issuance of the license for the production and trading of products made within the UAE or internationally.
                Our team of experts is well-versed with the rules and regulations set by the Department of Economic Development (DED) and the UAE government regarding the issuance of an industrial license. We assist our clients by providing all the necessary information and guidance on the documents required to obtain the license. Our services also include helping our clients prepare and submit their license applications to the relevant authorities.

              </AccordionPanel>
            </AccordionItem>

            <AccordionItem className="drop-para">
              <h2>
                <AccordionButton>
                  <Box
                    flex="1"
                    textAlign="left"
                    className="bold-text color-text font2"
                  >
                    HOW TO OBTAIN AN INDUSTRIAL LICENSE IN DUBAI MAINLAND?
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4} className="para-small">
                To obtain an industrial license in Dubai, meeting several requirements is essential, and having a physical premise is one of the most critical ones. Virtual offices are not permissible, and therefore, it's necessary to have a physical office space. Additionally, a local industrial license is mandatory and must be obtained from the relevant authority in the business area you choose. It's particularly crucial if you opt for a free zone as the free zone authorities differ based on your company's operating area.
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem className="drop-para">
              <h2>
                <AccordionButton>
                  <Box
                    flex="1"
                    textAlign="left"
                    className="bold-text color-text font2"
                  >
                    WHAT ARE THE REQUIREMENTS FOR AN INDUSTRIAL LICENSE IN DUBAI?
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4} className="para-small">
                Future Business Solutions (FBS) can help you understand the maximum number of visas you can apply for when setting up a business in mainland Dubai. Although there are no strict limitations on the number of visas, the allocation of visas is based on the size of the office space. For every 9 square meters of office space, approximately one visa can be allotted. This means that the number of visas your company can receive is dependent on the size of your office space - the larger the office space, the more visas your company may be eligible for. At FBS, we can assist you in determining the number of visas your company is eligible for based on your office space size.
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem className="drop-para">
              <h2>
                <AccordionButton>
                  <Box
                    flex="1"
                    textAlign="left"
                    className="bold-text color-text font2"
                  >
                    WHAT ARE SOME PRIVILEGES OF HAVING AN INDUSTRIAL LICENSE?
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4} className="para-small">
                Revised: Companies that possess an industrial license are granted exclusive privileges to promote the growth of the industrial sector and attract significant investments. These benefits include the government providing land for industrial establishment at subsidized rates, discounted rates for essential resources such as electricity and water, and often, expert guidance to optimize profits.
                With an industrial license, firms can capitalize on these advantages to expand their operations and achieve sustainable growth, which contributes to the overall development of the industry and the economy.
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem className="drop-para">
              <h2>
                <AccordionButton>
                  <Box
                    flex="1"
                    textAlign="left"
                    className="bold-text color-text font2"
                  >
                    HOW CAN FBS LLC HELP WITH THE APPLICATION FOR AN INDUSTRIAL LICENSE?
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4} className="para-small">
                FBS simplifies the process of establishing an industrial company in Dubai, which can be a daunting task for many foreigners. With our experience and diligence, we help entrepreneurs stand on their own by ensuring that all necessary documents are in order and handling legal technicalities on their behalf. We facilitate the application for your industrial license from the Dubai Department of Economic Development (DED), making the process seamless and hassle-free.
              </AccordionPanel>
            </AccordionItem>

          </Accordion>
        </div>
      </div>
      <br></br>
      <br></br>
      <br></br>

      <br></br>
      <Contact />
      <hr></hr>
    </>
  );
};

export default Industrial;
