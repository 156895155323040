import { AiFillFacebook, AiFillLinkedin, AiFillSkype,  AiOutlineInstagram } from "react-icons/ai";
import "./footer.css";
import { BsBuilding } from "react-icons/bs";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer style={{ marginBottom: "-2rem" }} className="mt-5">
      <div
        className="background5"
        style={{ background: " rgb(114 36 147)", opacity: "" }}
      >
        <div className="transbox5">
          <div className="container-fluid">
            <br></br>
            <br></br>
            <div className="row padding2">
              <div className="col-sm-12 col-md-6 col-lg-4 center2  mb-5">
                <ul>
                  <h3 className="text-white bold-text mt-4 h4 d-flex">
                    <BsBuilding className="mx-1" />Future Business Solutions {" "}
                  </h3>
                  {/* <hr className="color-hr"></hr>
                   */}
                  <p style={{ borderBottom: "1px solid white", paddingBottom: "5px", width: "5rem" }}></p>

                  <li className="zoom marginFooter para-small mt-2">
                    <Link to="/about" className="text-white color mt-1 ">
                      {" "}
                      {/* <i
                          className="fa fa-angle-right "
                          aria-hidden="true"
                        ></i>{" "} */}
                      About Us
                    </Link>
                  </li>
                  <li className="zoom marginFooter para-small mt-1">
                    <Link to="/contact" className="text-white color mt-1 ">
                      {" "}
                      {/* <i
                          className="fa fa-angle-right "
                          aria-hidden="true"
                        ></i>{" "} */}
                      Contact Us
                    </Link>
                  </li>
                  <li className="zoom marginFooter mt-3 d-flex">
                    <a href="/" className="text-white color mt-1 ">
                      {" "}

                      <AiOutlineInstagram className="icon-social-small " />
                    </a>
                    <a href="/" className="text-white color mt-1 ">
                      {" "}

                      <AiFillLinkedin className="icon-social-small " />
                    </a>
                    <a href="/" className="text-white color mt-1 ">
                      {" "}

                      <AiFillFacebook className="icon-social-small " />
                    </a>
                    <a href="/" className="text-white color mt-1 ">
                      {" "}

                      <AiFillSkype className="icon-social-small " />
                    </a>
                  </li>
									<li className="zoom  para-small mt-4">
										
											<Link to="/contact" className="button-effect-white4 fill5">
												Request your free consultation
											</Link>
									
									</li>

								

                  {/* <p className="mt-3 text-white" style={{ fontSize: "13px" }}>
                    {" "}
                    Future Business Solutions  (FBS) is based in Dubai, a dynamic business hub; and is capable of helping anyone with a dream to not just own but successfully conduct business and be a part of the fastest growing economies. With a decade of experience in Business setup and corporate PRO services, our all-embracing attitude helps initially small, foreign but path-breaking enterprises to realize their full potential all over UAE.
                  </p>
                  <button className="button-effect-white3  fill4  mt-4">
                    Know More
                  </button> */}
                </ul>
              </div>

              <div className="col-sm-12 col-md-6 col-lg-4  mb-5 ">
                <div className="center2 ">
                  <ul>
                    <br />
                    <h3 className="text-white  bold-text h4">Quick Links</h3>
                    {/* <hr className="color-hr"></hr> */}
                    <p style={{ borderBottom: "1px solid white", paddingBottom: "5px", width: "5rem" }}></p>
                    <li className="zoom marginFooter para-small mt-2">
                      <Link to="/mainland" className="text-white color">
                        {" "}
                        {/* <i
                          className="fa fa-angle-right "
                          aria-hidden="true"
                        ></i>{" "} */}
                        Mainland License
                      </Link>
                    </li>
                    <li className="zoom marginFooter para-small  mt-2">
                      {" "}
                      <Link to="/freezone" className="text-white color mt-3">
                        {" "}
                        {/* <i
                          className="fa fa-angle-right"
                          aria-hidden="true"
                        ></i>{" "} */}
                        Free zone License
                      </Link>
                    </li>
                    <li className="zoom marginFooter para-small  mt-2">
                      <Link to="/offshore" className="text-white  color  ">
                        {/* <i className="fa fa-angle-right" aria-hidden="true"></i>{" "} */}
                        Offshore License
                      </Link>
                    </li>
                    <li className="zoom marginFooter para-small">
                      {" "}
                      <Link to="/businessvisa" className="text-white color  ">
                        {" "}
                        {/* <i
                          className="fa fa-angle-right"
                          aria-hidden="true"
                        ></i>{" "} */}
                        Dubai-Business visa
                      </Link>
                    </li>
                    <li className="zoom marginFooter para-small  mt-2">
                      {" "}
                      <Link to="/investorvisa" className="text-white color  ">
                        {" "}
                        {/* <i
                          className="fa fa-angle-right"
                          aria-hidden="true"
                        ></i>{" "} */}
                        Dubai-Investor visa
                      </Link>
                    </li>
                    {/* <li className="zoom marginFooter  mt-3">
                      {" "}
                      <Link to="/blog" className="text-white color  ">
                        {" "}
                        <i
                          className="fa fa-angle-right"
                          aria-hidden="true"
                        ></i>{" "}
                        Blog
                      </Link>
                    </li> */}
                  </ul>
                </div>
              </div>
              {/* <div className="col-sm-12 col-md-6 col-lg-3 mt-1 mb-5">
                <ul className="mx-2">
                  <br />
                  <h3 className="text-white h5 ">Mainland License</h3>
                  <hr className="color-hr"></hr>
                  <li className="zoom marginFooter mt-3">
                    <Link to="/professional" className="text-white color mt-1 ">
                      {" "}
                      <i
                        className="fa fa-angle-right"
                        aria-hidden="true"
                      ></i>{" "}
                      Professional License
                    </Link>
                  </li>

                  <li className="zoom marginFooter  mt-3">
                    {" "}
                    <Link to="/commericial" className="text-white  color ">
                      {" "}
                      <i
                        className="fa fa-angle-right"
                        aria-hidden="true"
                      ></i>{" "}
                      Commercial License
                    </Link>
                  </li>
                  <li className="zoom marginFooter  mt-3">
                    <Link to="/industrial" className="text-white  color ">
                      <i className="fa fa-angle-right" aria-hidden="true"></i>{" "}
                      Industrial License
                    </Link>
                  </li>
                  <li className="zoom marginFooter">
                    {" "}
                    <Link to="/tourism" className="text-white color  ">
                      {" "}
                      <i
                        className="fa fa-angle-right"
                        aria-hidden="true"
                      ></i>{" "}
                      Tourism License
                    </Link>
                  </li>
                  <li className="zoom marginFooter  mt-3">
                    {" "}
                    <Link to="/etrade" className="text-white color  ">
                      {" "}
                      <i className="fa fa-angle-right" aria-hidden="true"></i> E
                      Trader Lincense
                    </Link>
                  </li>
                </ul>
              </div> */}

              <div
                className="col-sm-12 col-md-6 col-lg-4  mb-2"
                style={{ fontFamily: "Poppins" }}
              >
                <ul>
                  <br />
                  <h3 className="text-white h4 bold-text"> Our Contact</h3>
                  {/* <hr className="color-hr"></hr> */}
                  <p style={{ borderBottom: "1px solid white", paddingBottom: "5px", width: "5rem" }}></p>
                  <li className="text-white para-small  mt-2">
										<i className="fa fa-map-marker mx-1"></i>Room number 405 Floor 4  57RM+3P2 - Marasi Dr - <br></br> Business Bay - Dubai - United Arab Emirates

                  </li>
               

                  <li className="text-white para-small d-flex mt-2">
                    {" "}
                    <i className="fa fa-phone-square mx-1 mt-1"></i> +971 52 914 8888

                  </li>
                  {/* <li className="text-white para-small mt-2">
                    {" "}
                    <i className="fa fa-envelope  mx-1"></i>{" "}
                    dhananjayavare786@gmail.com
                  </li> */}
                  <br />

                  <br />
                </ul>
              </div>
            </div>
            <hr className="color-hr"></hr>

            <div className="icon-social color5">
              <h5 className="padding text-white ">
                @ 2022 Copyright FBS.com All Rights reserved
              </h5>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
