import React, { useState, useRef } from "react";


import { db } from "../FirebaseConfig";
import { uid } from "uid";
import { set, ref, serverTimestamp } from "firebase/database";
import { Form, InputGroup, Button } from "react-bootstrap";
import emailjs from "@emailjs/browser";
import { BsFillChatRightTextFill, BsFillTelephoneInboundFill } from "react-icons/bs";
// import { HiChatBubbleLeftRight } from "react-icons/hi";
import { HiLocationMarker } from "react-icons/hi";
import { MdAddLocationAlt, MdEmail } from "react-icons/md";
import { AiFillWechat, AiOutlineWhatsApp } from "react-icons/ai";
import { useEffect } from "react";
import PhoneInput from 'react-phone-input-international'
import 'react-phone-input-international/lib/style.css'


import img from "../Images/contactus.jpeg";
import { MdPhoneCallback } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
// info @diplomatdoc.com

const ContactUs = () => {

	const [name, setName] = useState("");
	const [lastname, setLastname] = useState("");
	const [phone, setPhone] = useState("");
	const [email, setEmail] = useState("");
	const [mess, setMess] = useState("");
	const [message, setMessage] = useState({ error: false, msg: "" });

	const navigate = useNavigate();

	useEffect(() => {
		setTimeout(() => {
			window.scrollTo(0, 0);
		}, 0);
	}, []);
	const form = useRef();

	const sendEmail = (e) => {
		e.preventDefault();
		console.log(e.target.phone);
		// service_4yk79ob
		emailjs
			.sendForm(
				"service_mvqum2y",
				"template_8iujjgi",
				form.current,
				"G-TZUKW8g_6IYTxKa"
			)
			.then(
				(result) => {
					console.log(result.text);
					setName("");
					setLastname("");
					setPhone("");
					setEmail("");
					setMess("");
					// window.location.reload();
				},
				(error) => {
					console.log(error.text);
				}
			);
	};
	console.log(phone)
	const handleSubmit = async (e) => {
		const uuid = uid();
		e.preventDefault();
		setMessage("");
		if (
			name === "" ||
			lastname === "" ||
			phone === "" ||
			email === "" ||
			mess === ""
		) {
			setMessage({ error: true, msg: "All fields are mandatory!" });
			return;
		} else {
			set(ref(db, `/${uuid}`), {
				name: name,
				lastname: lastname,
				phone: phone,
				email: email,
				mess: mess,
				timeStamp: serverTimestamp(),
			});

			setMessage({ error: false, msg: "Contact Submit successfully" });
			navigate('/thank-you')
		}

		setName("");
		setLastname("");
		setPhone("");
		setEmail("");
		setMess("");
	};

	const handleSubmitData = (e) => {
		e.preventDefault();
		if (
			name === "" ||
			lastname === "" ||
			phone === "" ||
			email === "" ||
			mess === ""
		) {
			setMessage({ error: true, msg: "All fields are mandatory!" });
			return;
		}
		handleSubmit(e);
		sendEmail(e);
	};

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	useEffect(() => {
		// Add the GTM script dynamically to the head of the document
		const script = document.createElement('script');
		script.async = true;
		script.src = 'https://www.googletagmanager.com/gtag/js?id=G-4Y7FD8DFE9';
		document.head.appendChild(script);

		script.onload = () => {
			// Execute the inline script once the external script is loaded
			const inlineScript = document.createElement('script');
			inlineScript.type = 'text/javascript';
			inlineScript.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments)}
        gtag('js', new Date());
        gtag('config', 'G-4Y7FD8DFE9');
      `;
			document.head.appendChild(inlineScript);
		};

		return () => {
			// Cleanup scripts on component unmount
			document.head.removeChild(script);
		};
	}, []);


	const navigateToChat = (link) => {
		window.open(link, '_blank');
	}

	return (
		<>
			<Helmet>
				<title>Contact US</title>
				<meta name="description" content="All license here" />
				<script async src={`https://www.googletagmanager.com/gtag/js?id=G-4Y7FD8DFE9`}></script>
				<script>
					{`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments)}
          gtag('js', new Date());
          gtag('config', 'G-4Y7FD8DFE9');
        `}
				</script>

			</Helmet>

			<div className="row">
				<img src={img} className="img-fluid" alt="error"  ></img>
			</div>
			<div className="container bg-white  rounded margin-top-custom shadow" style={{ backgroundColor: "white", zIndex: 1, position: "relative" }}  >
				<br></br>

				{/* {message?.msg && (
          <Alert
            variant={message?.error ? "danger" : "success"}
            dismissible
            onClose={() => setMessage("")}
          >
            {message?.msg}
          </Alert>
        )} */}
				<div className="row">

					<div className="col-md-12  col-lg-5 col-xl-5">
						{/* <img src={aboutus} className="img-fluid mt-3"></img> */}
						{/* <p className="maps mt-2 mx-2"  ><iframe title='demo' style={{ width: "100%", height: "10rem", border: "0", borderRadius: 10, allowfullscreen: "", loading: "lazy" }} src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4171.969212461!2d55.385265000000004!3d25.264207!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f5da287a20f23%3A0x41a2a8197736e21f!2sSharjah%20Islamic%20Bank%2C%20Al%20Twar%20Branch!5e1!3m2!1sen!2sin!4v1677872177680!5m2!1sen!2sin" ></iframe></p> */}
						{/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d9025.238507712007!2d73.12579754368163!3d19.236387869628214!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7954a866ebbd1%3A0xe9010fdbbc8d4393!2sD-Mart!5e1!3m2!1sen!2sin!4v1675111883745!5m2!1sen!2sin" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}

						{/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4171.969212461!2d55.385265000000004!3d25.264207!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f5da287a20f23%3A0x41a2a8197736e21f!2sSharjah%20Islamic%20Bank%2C%20Al%20Twar%20Branch!5e1!3m2!1sen!2sin!4v1677872177680!5m2!1sen!2sin" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}

						{/* <div className="mx-3 mt-4">
              <p className="text-dark   d-flex" style={{ marginTop: "-5px" }} ><BsFillTelephoneInboundFill className="h3  mx-1" style={{ background: "#00B4D8", padding: "7px", borderRadius: 20, color: "white" }} /> <span className="bold-text"> Main Office: </span>  +971 4 326 2732 </p>
              <p className="text-dark    d-flex" style={{ marginTop: "-5px" }} ><BsFillTelephoneInboundFill className="h3  mx-1" style={{ background: "#00B4D8", padding: "7px", borderRadius: 20, color: "white" }} /> <span className="bold-text">Branch Office: </span> +971 4 251 0444  </p>
              <p className="text-dark  d-flex" style={{ marginTop: "-10px" }}  ><AiOutlineWhatsApp AiOutlineWhatsApp className="h3  mx-1" style={{ background: "#00B4D8", padding: "7px", borderRadius: 20, color: "white" }} /><span className="bold-text">Whatsapp: </span> +971 50 887 8699</p>
              <p className="text-dark   d-flex" style={{ marginTop: "-10px" }}  ><BsFillTelephoneInboundFill className="h3  mx-1" style={{ background: "#00B4D8", padding: "7px", borderRadius: 20, color: "white" }} /> <span className="bold-text">Phone: </span> +971 52 895 1898</p>
              <p className="text-dark bold-text  d-flex" style={{ marginTop: "-5px" }} ><MdEmail className="h3 mx-1" style={{ background: "#00B4D8", padding: "7px", borderRadius: 20, color: "white" }} /> Info@diplomatdoc.com</p>
              <p className="text-dark  bold-text d-flex" style={{ marginTop: "-5px" }} ><HiLocationMarker className="h3 mx-1" style={{ background: "#00B4D8", padding: "4px", borderRadius: 22, color: "white" }} /> Office No:305,Coastal Building Al Twar - 2,<br></br> Near Sharjah Islamic Bank,Dubai P.O Box: 235548</p>
              <p className="text-dark bold-text  d-flex"> <HiLocationMarker className="h3 mx-1" style={{ background: "#00B4D8", padding: "4px", borderRadius: 20, color: "white" }} />Office No:G 21,Arzoo Real Estate Building<br></br> Al Twar - 2,  Near Sharjah Islamic Bank,Dubai P.O Box: 235548</p>
            </div> */}

						<div className="shadow mt-3" style={{cursor:'pointer'}} onClick={() => navigateToChat('tel:+971529148888')} >

							{/* <a href="https://wa.me/971529148888" className="float" target="_blank" id='whatsapp' rel="noreferrer">
								<AiOutlineWhatsApp className="fa fa-whatsapp my-float" />

							</a> */}
							<div className="row mx-2">

								<div className="col-md-3 col-sm-3 col-3">
									<MdPhoneCallback className="icon-service" style={{ fontSize: "50px", marginTop: "1rem" }} />
								</div>
								<div className="col-md-9 col-sm-9  col-9"  >

									<a className="" href="tel:+971529148888" target="_blank" id="phone" rel="noreferrer">
										<p className="bold-text d-flex" style={{ fontSize: "20px" }}>
											<span className="margin-top2 mt-4 mx-1"> Call us directly at</span>
										</p>
										<p style={{ fontSize: "19px" }}>
											+971 52 914 8888
										</p>
									</a>
								</div>
							</div>
						</div>
						<div className="shadow mt-2" style={{ cursor: 'pointer' }} onClick={() => navigateToChat('https://wa.me/971529148888')} >
							<div className="row mx-2">

								<div className="col-md-3 col-sm-3 col-3">
									<AiFillWechat className="icon-service" style={{ fontSize: "50px", marginTop: "1rem" }} />
								</div>
								<div className="col-md-9 col-sm-9  col-9">
									<div className="">
										<p className="bold-text d-flex" style={{ fontSize: "20px" }}>
											<span className="margin-top2 mt-4 mx-1">Chat with our sales team</span>
										</p>
										<p className=" mx-1" style={{ fontSize: "19px" }}>
											Contact us
										</p>
									</div>
								</div>
							</div>
						</div>
						<div className="shadow mt-2" >
							<div className="row mx-2">

								<div className="col-md-3 col-sm-3  col-3">
									<MdAddLocationAlt className="icon-service" style={{ fontSize: "50px", marginTop: "1rem" }} />
								</div>
								<div className="col-md-9 col-sm-9  col-9">
									<div className="">
										<p className="bold-text d-flex" style={{ fontSize: "20px" }}>
											<span className="margin-top2 mt-2 mx-1">location</span>
										</p>
										<p style={{ fontSize: "15px" }} className="mx-1">
											Room number 405 Floor 4  57RM+3P2 - Marasi Dr - Business Bay - Dubai - United Arab Emirates
										</p>
									</div>
								</div>
							</div>
						</div>
						{/* <div className="shadow mt-2">
              <div className="row mx-2 mt-2">

                <div className="col-md-3 col-sm-3  col-3">
                  <MdAddLocationAlt className="icon-service" style={{ fontSize: "30px", marginTop: "0rem" }} />
                </div>
                <div className="col-md-9 col-sm-9  col-9">
                  <div className="">
                    <p className="bold-text d-flex" style={{ fontSize: "20px" }}>
                      <span className="margin-top2 mt-2"> location</span>
                    </p>
                    <p style={{ fontSize: "19px" }}>
                      +4568945656
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="shadow mt-2">
              <div className="row mx-2 mt-2">

                <div className="col-md-3 col-sm-3  col-3">
                  <MdAddLocationAlt className="icon-service" style={{ fontSize: "30px", marginTop: "0rem" }} />
                </div>
                <div className="col-md-9 col-sm-9  col-9">
                  <div className="">
                    <p className="bold-text d-flex" style={{ fontSize: "20px" }}>
                      <span className="mt-2"> location</span>
                    </p>
                    <p style={{ fontSize: "19px" }}>
                      +4568945656
                    </p>
                  </div>
                </div>
              </div>
            </div> */}

					</div>

					<div className="col-md-12  col-lg-7 col-xl-7">

						<div className="shadow mx-2 mt-3">

							<div className="center mt-3">
								<p className="h4 bold-text ">Book a Meeting</p>
								<p className="text-secondary mt-4">To schedule time to speak with one of out sales representatives , complete the form,then click "next" to choose a date and time that works for you</p>
							</div>


							<Form ref={form} onSubmit={handleSubmitData}>
								<div className="row mt-4 mx-1">
									<div className="col-md-12 mt-2" style={{ padding: '2px' }}>
										<Form.Group className="input-fluid" controlId="formName">
											{/* <p className="paragraph-small">FirstName:</p> */}
											<InputGroup className=" mt-2">
												<Form.Control
													type="text"
													name="name"
													placeholder="FirstName"
													className="form-control"
													value={name}
													onChange={(e) => setName(e.target.value)}
												/>
											</InputGroup>
										</Form.Group>
									</div>

								</div>
								<div className="row mx-1">
									<div className="col-md-12 mt-2 mx-1" style={{ padding: '2px' }}>
										<Form.Group className=" input-fluid mb-3" controlId="formlast" style={{ marginLeft: "-0.3rem" }} >
											{/* <p className="paragraph-small">LastName:</p> */}
											<InputGroup className=" mt-2">
												<Form.Control
													type="text"
													name="lastname"
													placeholder="LastName"
													value={lastname}
													onChange={(e) => setLastname(e.target.value)}
												/>
											</InputGroup>
										</Form.Group>
									</div>
								</div>
								<div className="row mx-1" style={{ marginTop: '-10px' }} >
									<div className="col-md-12 mt-2 " style={{ padding: '2px' }}>
										<Form.Group className="input-fluid mt-2" style={{ width: "100%" }} controlId="formPhone">
											{/* <p className="paragraph-small">Phone:</p> */}
											{/* <InputGroup className=" mt-2">
                      <Form.Control
                        type="number"
                        placeholder="Phone"
                        name="phone"
                        className="form-control"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                      />
                    </InputGroup> */}
											<InputGroup className="mx-1">
												<PhoneInput
													country={'ae'}
													inputProps={{
														name: 'phone',
														required: true,

													}}
													containerStyle={{

														marginLeft: "2px",
														borderRadius: "10px"
													}}
													inputStyle={{
														borderRadius: "10px",
														height: "3rem",
														width: "99%"
													}}
													name="phone"
													value={phone}
													style={{ width: "100%" }}
													onChange={event => setPhone(event)}
												/>
												{/* <Form.Control
                          style={{ color: "black", }}
                          placeholder="Phone"
                          name="phone"
                          type="number"
                          className=""
                          value={phone}
                          onChange={e => setPhone(e.target.value)}
                        /> */}
											</InputGroup>
										</Form.Group>
									</div>

								</div>
								<div className="row mx-1">
									<div className="col-md-12  mt-2" style={{ padding: '2px' }}>
										<Form.Group className="input-fluid" controlId="formEmail">
											{/* <p className="paragraph-small">Email:</p> */}
											<InputGroup className=" ">
												<Form.Control
													type="email"
													placeholder="Email"
													name="email"
													className="form-control mt-2 "
													value={email}
													onChange={(e) => setEmail(e.target.value)}
												/>
											</InputGroup>
										</Form.Group>
									</div>
								</div>
								{/* <div className="row ">

                <div className="col-md-12 mt-2">
                  <Form.Group className="input-fluid" controlId="formEmail">
                    <p className="paragraph-small">Email:</p>
                    <InputGroup className=" mt-2">
                      <Form.Control
                        type="email"
                        placeholder="Email"
                        name="email"
                        className="form-control"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </InputGroup>
                  </Form.Group>
                </div>
              </div> */}

								<div className="row mx-1">
									<div className="col-md-12 mt-3" style={{ padding: '2px' }}>
										<Form.Group className="input-fluid" controlId="formMessage">
											{/* <p className="paragraph-small">Message:</p> */}
											<InputGroup className=" mt-2">
												<Form.Control
													type="text"
													name="mess"
													placeholder="Message"
													className="form-control"
													value={mess}
													onChange={(e) => setMess(e.target.value)}
												/>
											</InputGroup>
										</Form.Group>
									</div>
								</div>
								<div className="row mt-5 text-dark" style={{ color: "black" }}>
									<p className="text-dark">
										<input type="checkbox" name="" value="" className="mx-2" />
										By checking this box , I understand and acknowledge that the
										information shared above is genuine to the best of my knowledge.
									</p>
									<p className="mx-2">
										This site is protected by reCAPTCHA and the Google
										<Link to="/" className="mx-1 text-primary">
											Privacy Policy{" "}
										</Link>
										and{" "}
										<Link to="/" className="mx-1 text-primary">
											Terms{" "}
										</Link>{" "}
										of Service apply.
									</p>

								</div>

								<div className="mx-1 mt-2">
									<Button
										type="Submit"

										className="button-animation"
										style={{ width: "100%", backgroundColor: "rgb(114, 36, 147)" }}
									>
										SUBMIT
									</Button>
								</div>
							</Form>


						</div>
					</div>
				</div>
			</div >

		</>);
};

export default ContactUs;
