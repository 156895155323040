import React, { useEffect, useState } from 'react'
import { doc, getDoc } from "firebase/firestore";
import { useParams } from 'react-router-dom';
import { store } from '../FirebaseConfig';

const SingleBlog = async () => {

    const { id } = useParams();
    const [data, setData] = useState([]);
    console.log(id)

    useEffect(() => {

        const fetchSingleData = async () => {

            try {
                const docRef = doc(store, "blog", id);
                const docSnap = await getDoc(docRef);
                if (docSnap.exists()) {
                    console.log(docSnap.data());
                    setData(docSnap.data());
                } else {
                    // doc.data() will be undefined in this case
                    console.log("No such document!");
                }
            } catch (error) {
                console.log(error)
            }

        }
        fetchSingleData();
    }, [])


    return (
        <div className='container'>

            heyy
            <div className='row'>
                <div className='col-md-6'>
                    <img src={data.img} className='img-fluid'></img>

                </div>
                <div className='col-md-6'>
                </div>

            </div>

        </div>
    )
}

export default SingleBlog
