import React, { useEffect } from "react";
import img from "../../Images/2.png";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  Box,
  AccordionIcon,
  AccordionPanel,
} from "@chakra-ui/react";

import img1 from "../../Images/b4.png";
import Contact from "../../components/Contact";
import SmallStrip from "../../components/SmallStrip";
import ModalContact2 from "../../components/ModalContact2";
import { Helmet } from "react-helmet";

const FreeZone = () => {

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 0);
  }, []);

	useEffect(() => {
		// Add the GTM script dynamically to the head of the document
		const script = document.createElement('script');
		script.async = true;
		script.src = 'https://www.googletagmanager.com/gtag/js?id=G-4Y7FD8DFE9';
		document.head.appendChild(script);

		script.onload = () => {
			// Execute the inline script once the external script is loaded
			const inlineScript = document.createElement('script');
			inlineScript.type = 'text/javascript';
			inlineScript.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments)}
        gtag('js', new Date());
        gtag('config', 'G-4Y7FD8DFE9');
      `;
			document.head.appendChild(inlineScript);
		};

		return () => {
			// Cleanup scripts on component unmount
			document.head.removeChild(script);
		};
	}, []);

  return (
    <>
			<Helmet>
				<title>FreeZone</title>
				<meta name="description" content="All license here" />
				<script async src={`https://www.googletagmanager.com/gtag/js?id=G-4Y7FD8DFE9`}></script>
				<script>
					{`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments)}
          gtag('js', new Date());
          gtag('config', 'G-4Y7FD8DFE9');
        `}
				</script>
			</Helmet>
      <SmallStrip data="FreeZone" data2="Licensing" />

      <div className="container-fluid padding3">
        <div className="row">
          <div className="col-md-6 col-lg-4 col-xl-4 center">
            <img src={img} alt="error" className="img-fluid rounded mt-1" />
          </div>
          <div className="col-md-6 col-lg-8 col-xl-8">
            <p className="heading-text-h1 bold-text " >Dubai FreeZone License, UAE</p>
						<p className="para-small mt-2">
              Dubai has long been recognized as a pioneer of the free zone model of business, offering an array of concessions and investment incentives to foreign entrepreneurs. As a result, the city has become a hub for international business and has attracted many investors from around the world. With around 24 free zones currently active, each one has been developed with specific industries or sectors in mind, such as ICT, finance, gold, media, and healthcare.
           
            </p>
						<p className="para-small mt-2">
						
							One of the most attractive aspects of setting up a business in a free zone is the various exemptions that companies enjoy. These include exemptions from corporate tax and customs duty on imported materials and equipment, as well as no levy on imports or exports. However, it is important to note that a free zone license is mandatory for any type of business in the free zone.
							Obtaining a free zone license from the respective free zone authority allows national or international companies to start a new branch office or shift completely into the free zone. However, it is crucial to conduct thorough research into the various types of free zone areas and their benefits to find the most favorable fit that will enhance profits.
							
						</p>
						<p className="para-small mt-2">
							
							Dubai's strategic location and the various benefits of operating a business in the UAE, such as zero taxation on both corporate and personal income, make it an attractive destination for investors. Future Business Solutions (FBS) offers business support services and can assist in repatriating all capital and profits with no currency restrictions when operating within the free zone.
							FBS has a wealth of experience and knowledge in the local business practices of the chosen free zone area, as well as an economic approach to starting a company while maintaining a desired timeframe. This ensures a smooth and stress-free process for those looking to set up a business in Dubai's free zones.

						</p>
            <br></br>

            <ModalContact2 />
          </div>
        </div>
      </div>
      <br></br>


      <div className="container-fluid padding3">
        <div className="row">
          <center>
            <p className="heading-text-h1 bold-text color">FreeZone</p>
          </center>
          <br></br>

          <br></br>

          <p className="para-small">
            The free zone is an excellent location to start a business because it offers unique advantages that are not available in other areas. As a foreigner starting a company in the free zone, you won't face the typical restrictions that can hinder your business success. For instance, you won't need to have a local partner or sponsor to establish your business in the area.
            One of the primary perks of starting a business in the free zone is that you will enjoy a tax-free experience, both on a personal and corporate level. This means that you won't be subject to any corporate or personal income taxes, making it an attractive option for entrepreneurs looking to minimize their tax liabilities.

          </p>

          {/* <div className="col-lg-4 col-md-6 col-xl-4 mt-4">
            <img src={img1} className="img-fluid" alt="error" />
          </div>
          <div className="col-lg-4 col-md-6 col-xl-4 mt-4">
            <img src={img1} className="img-fluid" alt="error" />
          </div>
          <div className="col-lg-4 col-md-6 col-xl-4 mt-4">
            <img src={img1} className="img-fluid" alt="error" />
          </div> */}

        </div>
      </div>

      <div className="container-fluid padding3 ">
        <br></br>
        <div className="shadow">
          <Accordion defaultIndex={[0]} allowMultiple>
            <AccordionItem className="drop-para">
              <h2>
                <AccordionButton>
                  <Box
                    flex="1"
                    textAlign="left"
                    className="bold-text color-text font2"
                  >
                    WHAT IS THE FREE ZONE AND WHAT ARE THE PERKS OF STARTING A BUSINESS IN THIS AREA?
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4} className="para-small">
                The free zone is a highly advantageous area for entrepreneurs looking to start a business. This location offers a unique advantage as it provides exemption from many of the typical restrictions that can hinder foreign entrepreneurs when starting a business. One of the most significant benefits of setting up a business in a free zone is that there is no requirement for a local partner or sponsor, and businesses can enjoy a tax-free experience for both personal and corporate taxation. Additionally, there is no need to pay customs duties on both imports and exports, making it an ideal location for companies looking to conduct international trade. Overall, the free zone is an excellent location for those seeking to start a business with greater ease and cost-effectiveness.
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem className="drop-para">
              <h2>
                <AccordionButton>
                  <Box
                    flex="1"
                    textAlign="left"
                    className="bold-text color-text font2"
                  >
                    WHAT ARE THE BUSINESS ENTITIES ALLOWED IN THE FREE ZONE JURISDICTION?
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4} className="para-small">
                The Dubai free zone allows for three distinct types of business entities, which are as follows:
                Free Zone Company (FZC) - This is a type of business entity that requires a minimum of two shareholders to establish. It can be formed as a private or public limited company, and its shareholders can be individuals, corporate entities or a combination of both.
                Free Zone Establishment (FZE) - A FZE is a business entity with only one shareholder, who can be an individual or a corporate entity. The shareholder has complete control over the establishment's management and operations.

              </AccordionPanel>
            </AccordionItem>
            <AccordionItem className="drop-para">
              <h2>
                <AccordionButton>
                  <Box
                    flex="1"
                    textAlign="left"
                    className="bold-text color-text font2"
                  >
                    WHAT ARE BUSINESS LICENSES ISSUED IN THE DUBAI FREE ZONE?
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4} className="para-small">
                Dubai's Freezone Licenses are issued to various types of businesses, which include:
                Professional License - For service-oriented firms and consultancies.
                Industrial License - For manufacturing companies, as well as import and export businesses.
                General Trading License - For businesses engaged in trading various goods and commodities.
                Warehousing License - For companies involved in the storage and distribution of goods.
                Commercial License - This license allows businesses to request distributor services for both local and mainland operations.
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem className="drop-para">
              <h2>
                <AccordionButton>
                  <Box
                    flex="1"
                    textAlign="left"
                    className="bold-text color-text font2"
                  >
                    WHAT ARE THE BASIC STEPS TO HELP ATTAIN THE FREE ZONE LICENSE?
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4} className="para-small">
                To establish a business in a Dubai free zone, the following steps need to be taken:

                Choose a Business Entity - Select a suitable business entity type based on the nature of the business. Options include a free zone establishment, free zone company, or branch of an existing company.

                Register a Unique Trading Name - Choose a unique name for your business and register it with the relevant authorities.

                Apply for a License - Find the most appropriate license category for your business, such as a professional, industrial, general trading, warehousing, or commercial license. Apply for the license through the free zone authority.

              </AccordionPanel>
            </AccordionItem>
            <AccordionItem className="drop-para">
              <h2>
                <AccordionButton>
                  <Box
                    flex="1"
                    textAlign="left"
                    className="bold-text color-text font2"
                  >
                    HOW CAN FBS LLC HELP WITH THE APPLICATION FOR A FREE ZONE LICENSE?
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4} className="para-small">
                FBS LLC can provide valuable assistance with the application process for a Free Zone License in the following ways:

                Expert Consultancy - As experienced business consultants, FBS LLC can offer guidance and advice on the most suitable free zone area for your business, based on your industry and specific requirements.

                End-to-End Support - FBS LLC can partner with you throughout the entire process of obtaining a free zone license, from the initial application to the final approval from the corresponding free zone authority.

              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </div>
      </div>

      <hr></hr>
    </>
  );
};

export default FreeZone;
