import React, { useEffect } from "react";
import img from "../../../Images/15.png";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  Box,
  AccordionIcon,
  AccordionPanel,
} from "@chakra-ui/react";

import Contact from "../../../components/Contact";
import SmallStrip from "../../../components/SmallStrip";
import ModalContact2 from "../../../components/ModalContact2";
import { Helmet } from "react-helmet";

const Commericial = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
	useEffect(() => {
		// Add the GTM script dynamically to the head of the document
		const script = document.createElement('script');
		script.async = true;
		script.src = 'https://www.googletagmanager.com/gtag/js?id=G-4Y7FD8DFE9';
		document.head.appendChild(script);

		script.onload = () => {
			// Execute the inline script once the external script is loaded
			const inlineScript = document.createElement('script');
			inlineScript.type = 'text/javascript';
			inlineScript.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments)}
        gtag('js', new Date());
        gtag('config', 'G-4Y7FD8DFE9');
      `;
			document.head.appendChild(inlineScript);
		};

		return () => {
			// Cleanup scripts on component unmount
			document.head.removeChild(script);
		};
	}, []);

  return (
    <>
			<Helmet>
				<title>Commercial License</title>
				<meta name="description" content="All license here" />
				<script async src={`https://www.googletagmanager.com/gtag/js?id=G-4Y7FD8DFE9`}></script>
				<script>
					{`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments)}
          gtag('js', new Date());
          gtag('config', 'G-4Y7FD8DFE9');
        `}
				</script>
			</Helmet>
      <SmallStrip data="Commericial" data2="Licensing" />

      <div className="container-fluid padding3">
        <div className="row">
          <div className="col-md-6 col-lg-4 col-xl-4">
            <img src={img} alt="error" className="img-fluid rounded mt-1" />
          </div>
          <div className="col-md-6 col-lg-8 col-xl-8">
            <p className="heading-text-h1 bold-text color">Commercial License in Dubai</p>
						<p className="para-small mt-2" >
              To operate a successful business in Dubai or any other part of the UAE, obtaining a commercial license is essential. The commercial license provides businesses with legal authorization to sell and trade products or services in the UAE. However, it is important to note that the commercial license is different from a general trading license, as it is more specific and covers a limited number of products.
              One of the advantages of obtaining a commercial license is that it offers protection to businesses. The Department of Economic Development (DED) is responsible for regulating all commercial activities, ensuring that businesses operate within legal boundaries. This can help prevent any legal issues and ensure that businesses can operate smoothly and efficiently in the UAE.
            </p>
						<p className="para-small mt-2" >
							In addition, the commercial license allows businesses to hire foreign workers with ease, making it an ideal option for companies that plan to expand their workforce. The license also offers flexibility, as multiple business activities can be conducted under a single license. This can save businesses time and money, as they won't need to apply for multiple licenses for each activity they wish to conduct.
		
						</p>
						<p className="para-small mt-2" >
		
							However, it is important to note that a commercial license may not suffice for businesses that involve multiple specific activities. In such cases, additional licenses, permits, or authorizations may be required. Therefore, it is recommended to seek professional guidance from companies like Future Business Solutions (FBS), who can assist in the process of obtaining a commercial license, as well as other permits and authorizations that may be necessary for conducting business in the UAE.
						</p>
            <br></br>

            <ModalContact2 />
          </div>
        </div>
      </div>
      <br></br>

      <div className="container-fluid padding3 ">

        <div className="shadow">
          <Accordion defaultIndex={[0]} allowMultiple>
            <AccordionItem className="drop-para">
              <h2>
                <AccordionButton>
                  <Box
                    flex="1"
                    textAlign="left"
                    className="bold-text color-text font2"
                  >
                    WHAT DO YOU MEAN BY COMMERCIAL LICENSE IN DUBAI OR THE UAE?
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4} className="para-small">
                The term "commercial license" in Dubai and the UAE refers to a permit that allows individuals or businesses to engage in a wide range of economic activities. With a commercial license, one can trade in various goods and services, including importing and exporting within the UAE. The license permits the sale of a diverse range of products, such as food and beverages, clothing, toys, electronics, and appliances. Additionally, this license enables individuals to offer services such as brokering and real estate.
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem className="drop-para">
              <h2>
                <AccordionButton>
                  <Box
                    flex="1"
                    textAlign="left"
                    className="bold-text color-text font2"
                  >
                    WHAT ARE THE BASIC BUSINESS ACTIVITIES THAT COME UNDER THE COMMERCIAL LICENSE?
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4} className="para-small">
                The commercial license in Dubai and the UAE encompasses a broad range of business activities that a company can undertake. These activities include trading in goods and services, such as advertising, car rental, banking, stationery, waste management, and much more. If you are interested in obtaining a commercial license, FBS can provide you with a comprehensive list of all the activities that have been approved by the DED, giving you a better idea of the options available to you.
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem className="drop-para">
              <h2>
                <AccordionButton>
                  <Box
                    flex="1"
                    textAlign="left"
                    className="bold-text color-text font2"
                  >
                    WHAT ARE THE BENEFITS OF ACQUIRING A COMMERCIAL LICENSE?
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4} className="para-small">
                One of the main reasons for the growing popularity of acquiring a commercial license is the freedom it provides to trade in a wide range of goods and services. This is particularly advantageous in Dubai, which benefits from its strategic location and the presence of international airports, making it a hub for global trading. To ensure compliance with UAE business laws, FBS offers assistance with the necessary steps to begin trading.
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem className="drop-para">
              <h2>
                <AccordionButton>
                  <Box
                    flex="1"
                    textAlign="left"
                    className="bold-text color-text font2"
                  >
                    WHAT ARE THE STEPS TO ACQUIRE THE COMMERCIAL LICENSE?
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4} className="para-small">
                Revised: If you are interested in obtaining a commercial license, FBS offers a straightforward application process. The first step is to identify the specific business activities you plan to engage in. Next, you must choose a trading name that complies with government regulations. Finally, it's essential to ensure that all required documents are in order before submitting the application. FBS will handle the application process on your behalf, and typically, it takes only two weeks to complete.
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem className="drop-para">
              <h2>
                <AccordionButton>
                  <Box
                    flex="1"
                    textAlign="left"
                    className="bold-text  color-text font2"
                  >
                    HOW CAN FBS LLC HELP TO ACQUIRE THE COMMERCIAL LICENSE?
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4} className="para-small">
                Revised: At FBS, we understand that navigating the latest regulations can seem overwhelming. That's why our team of experts is highly experienced in helping you set up your company quickly and efficiently. We will guide you through the entire process, ensuring that you are fully compliant with all relevant regulations. Our streamlined approach means that you can expect to start trading within just a few weeks of engaging our services.
              </AccordionPanel>
            </AccordionItem>

          </Accordion>
        </div>
      </div>
      <br></br>
      <br></br>
      <br></br>

      <br></br>
      <Contact />
      <hr></hr>
    </>
  );
};

export default Commericial;
