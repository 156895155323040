import React, { useEffect } from "react";
import Contact from "../../components/Contact";
import ModalContact2 from "../../components/ModalContact2";
import SmallStrip from "../../components/SmallStrip";
import img1 from "../../Images/11.png";
import { Helmet } from "react-helmet";
const Why = () => {

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 0);
  }, []);

	useEffect(() => {
		// Add the GTM script dynamically to the head of the document
		const script = document.createElement('script');
		script.async = true;
		script.src = 'https://www.googletagmanager.com/gtag/js?id=G-4Y7FD8DFE9';
		document.head.appendChild(script);

		script.onload = () => {
			// Execute the inline script once the external script is loaded
			const inlineScript = document.createElement('script');
			inlineScript.type = 'text/javascript';
			inlineScript.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments)}
        gtag('js', new Date());
        gtag('config', 'G-4Y7FD8DFE9');
      `;
			document.head.appendChild(inlineScript);
		};

		return () => {
			// Cleanup scripts on component unmount
			document.head.removeChild(script);
		};
	}, []);

  return (
    <>
			<Helmet>
				<title>Why Start Business in Dubai?</title>
				<meta name="description" content="All license here" />
				<script async src={`https://www.googletagmanager.com/gtag/js?id=G-4Y7FD8DFE9`}></script>
				<script>
					{`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments)}
          gtag('js', new Date());
          gtag('config', 'G-4Y7FD8DFE9');
        `}
				</script>
			</Helmet>

      <SmallStrip data="Why" data2="Business-Guide" />

      <div className="container-fluid padding3">
        <div className="row">
          <div className="col-md-6 col-lg-4 col-xl-4">
            <img src={img1} alt="error" className="img-fluid rounded" />
          </div>
          <div className="col-md-6 col-lg-8 col-xl-8">
            <p className="heading-text-h1 bold-text color">
              Why start a Business in Dubai?{" "}
            </p>
            <p className="para-small mt-2">
              Since the birth of the modern Emirates, Dubai and UAE have been receptive to new talent and foreign businesses. It's hard to find new immigrants who would rather open a business in this country rather than settle here. Many people move to UAE due to its world-class infrastructure, powerful economy, skilled workforce, myriad services under one roof, and cultured diversity. Dubai has changed into a technological and innovation powerhouse that draws millions of people seeking to make huge profits.
            </p>
            <br></br>
            <ModalContact2 />
          </div>
        </div>
      </div>
      <br></br>
      <br></br>
      <br></br>

      <Contact />
    </>
  );
};

export default Why;
