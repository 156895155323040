import React, { useEffect } from "react";
import img from "../../../Images/20.png";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  Box,
  AccordionIcon,
  AccordionPanel,
} from "@chakra-ui/react";

import Contact from "../../../components/Contact";
import SmallStrip from "../../../components/SmallStrip";
import ModalContact2 from "../../../components/ModalContact2";
import { Helmet } from "react-helmet";

const Tourism = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
	useEffect(() => {
		// Add the GTM script dynamically to the head of the document
		const script = document.createElement('script');
		script.async = true;
		script.src = 'https://www.googletagmanager.com/gtag/js?id=G-4Y7FD8DFE9';
		document.head.appendChild(script);

		script.onload = () => {
			// Execute the inline script once the external script is loaded
			const inlineScript = document.createElement('script');
			inlineScript.type = 'text/javascript';
			inlineScript.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments)}
        gtag('js', new Date());
        gtag('config', 'G-4Y7FD8DFE9');
      `;
			document.head.appendChild(inlineScript);
		};

		return () => {
			// Cleanup scripts on component unmount
			document.head.removeChild(script);
		};
	}, []);

  return (
    <>
			<Helmet>
				<title>Tourism License</title>
				<meta name="description" content="All license here" />
				<script async src={`https://www.googletagmanager.com/gtag/js?id=G-4Y7FD8DFE9`}></script>
				<script>
					{`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments)}
          gtag('js', new Date());
          gtag('config', 'G-4Y7FD8DFE9');
        `}
				</script>
			</Helmet>
      <SmallStrip data="Tourism" data2="Licensing" />

      <div className="container-fluid padding3">
        <div className="row">
          <div className="col-md-6 col-lg-4 col-xl-4">
            <img src={img} alt="error" className="img-fluid rounded mt-1" />
          </div>
          <div className="col-md-6 col-lg-8 col-xl-8">
            <p className="heading-text-h1 bold-text color">Tourism License in Dubai, UAE</p>
						<p className="para-small  mt-2">
              Dubai's tourism industry is a major source of pride for the UAE, especially during the busy vacation season and events such as the Dubai Shopping Festival (DSF). Tourists from all over the world flock to Dubai to explore its many attractions, making it the top tourist destination in the Middle East. This lucrative industry invites investment and business opportunities, but to start a travel and tourism company in Dubai, one must first apply for a tourism license. Fortunately, Future Business Solutions (FBS) can help with the necessary paperwork and submission to the Department of Tourism and Commerce Marketing (DTCM) to obtain a tourism license. Dubai's exceptional administration has transformed it from a simple desert to one of the most beautiful and modern cities in the world. 
						
            </p>
						<p className="para-small  mt-2">
					 Its natural beauty is unmatched, with stunning skyscrapers, parks, and man-made islands adding to its charm and appeal to tourists. Dubai caters to all types of tourists, including those who practice Halal tourism, which makes it an ideal destination for Muslim families. Additionally, Dubai is also a popular destination for medical tourism and those seeking employment opportunities. 
						</p>
            
						<p className="para-small mt-2">
						 These factors contribute to Dubai's continued success as a top tourism hub. Investing in the travel and tourism industry in Dubai is a wise choice for those looking to establish themselves in this lucrative market. With its exceptional infrastructure and continued growth, Dubai is sure to remain a top destination for tourists from around the world.
						</p>
						<br/>
            <ModalContact2 />
          </div>
        </div>
      </div>
      <br></br>

      <div className="container-fluid padding3 ">
        <br></br>
        <div className="shadow">
          <Accordion defaultIndex={[0]} allowMultiple>
            <AccordionItem className="drop-para">
              <h2>
                <AccordionButton>
                  <Box
                    flex="1"
                    textAlign="left"
                    className="bold-text color-text font2"
                  >
                    WHAT ARE THE BENEFITS OF HAVING A TOURISM LICENSE IN THE DUBAI MAINLAND?
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4} className="para-small">
                Having a tourism license in Dubai mainland can provide numerous benefits, especially in light of the growing demand for tourism-related activities in the region. With its thriving business environment, Dubai mainland offers lucrative opportunities for entrepreneurs to expand their businesses and tap into new markets.
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem className="drop-para">
              <h2>
                <AccordionButton>
                  <Box
                    flex="1"
                    textAlign="left"
                    className="bold-text color-text font2"
                  >
                    WHAT ARE THE TYPES OF TOURISM LICENSES IN DUBAI?
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4} className="para-small">
                <p className="bold-text"> Inbound Tour License </p> <br></br>
                Dubai offers a range of business opportunities, including leading tours and organizing trips for visitors to explore the city. With its iconic landmarks, vibrant culture, and luxurious experiences, Dubai is a popular destination for tourists from around the world. Starting a tour and trip business in Dubai can be a lucrative venture, as long as you have the necessary permits and follow the regulations set by the local authorities. <br></br>
                <p className="bold-text">  Outbound Tour License </p><br></br>
                You are permitted to plan both domestic and international excursions outside of the UAE.<br></br>
                <p className="bold-text"> Travel Agent License</p> <br></br>
                Permitted to operate in ticket sales, hotel or resort bookings as well as car rental services.<br></br>
                <p className="bold-text">  Tour Guide License</p> <br></br>
                DTCM issues a tour guide license for a year after passing the exam for the same. Yearly renewal is mandatory.
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem className="drop-para">
              <h2>
                <AccordionButton>
                  <Box
                    flex="1"
                    textAlign="left"
                    className="bold-text color-text font2"
                  >
                    WHAT ARE THE ACTIVITIES THAT COME UNDER A TOURISM LICENSE?
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4} className="para-small">
                FBS can assist you in implementing your business idea in Dubai with the appropriate license. Our services include providing tourist visa services, travel insurance, booking hotels or resorts, and selling tourism packages under this license. With our support, you can ensure that your business venture in Dubai is set up for success.
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem className="drop-para">
              <h2>
                <AccordionButton>
                  <Box
                    flex="1"
                    textAlign="left"
                    className="bold-text color-text font2"
                  >
                    HOW CAN YOU OBTAIN A TOURISM LICENSE?
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4} className="para-small">
                The Dubai Tourism and Commerce Marketing (DTCM) has recently introduced updated regulations to streamline the process of obtaining a tourist license. With these new measures in place, FBS recommends that applicants provide a few basic documents to expedite their application, including a trading name that adheres to the government's naming guidelines, details of their business activities, a copy of the shareholders' passports, an entry stamp or visa page, and a passport-sized photograph. By providing these documents, applicants can ensure a smoother and simpler process of obtaining a tourist license in Dubai.
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem className="drop-para">
              <h2>
                <AccordionButton>
                  <Box
                    flex="1"
                    textAlign="left"
                    className="bold-text color-text font2"
                  >
                    HOW CAN FBS LLC HELP WITH GETTING A TOURISM LICENSE?
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4} className="para-small">
                If you have the ambition to start your own tourism company or partner with others to do so, but are unsure where to begin, FBS can provide valuable assistance. Our team is eager to take the burden off your shoulders and help you not only establish your business but also achieve the best possible results efficiently and cost-effectively.
              </AccordionPanel>
            </AccordionItem>


          </Accordion>
        </div>
      </div>
      <br></br>
      <br></br>
      <br></br>

      <br></br>
      <Contact />
      <hr></hr>
    </>
  );
};

export default Tourism;
