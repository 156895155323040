import React, { useRef } from "react";
import "./navbar.css";
import { useEffect, useState } from "react";
import logo from "../Images/logo.jpg"
import AOS from "aos";
import "aos/dist/aos.css"; //
import { GiHamburgerMenu } from "react-icons/gi";
import { AiOutlineClose, AiOutlineWhatsApp } from "react-icons/ai";
import { MdArrowDropDown } from "react-icons/md";
import { RiArrowDropRightFill } from "react-icons/ri";
import { Link } from "react-router-dom";

import ChakkraModal from "../homecomponents/ChakkraModal";
import { IoCallOutline } from "react-icons/io5";
const Navbar = () => {
  const [showMediaIcons, setShowMediaIcons] = useState(false);

  const ref = useRef()

  useEffect(() => {
    const checkIfClickedOutside = e => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (showMediaIcons && ref.current && !ref.current.contains(e.target)) {
        setShowMediaIcons(false)
      }
    }

    document.addEventListener("mousedown", checkIfClickedOutside)

    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside)
    }
  }, [showMediaIcons])

  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <>
      <section className="header ">
        <Link to="/" className="margin-left-500" style={{ marginLeft: "1rem" }}>
          <img src={logo} className="logo2" />
          {/* <p className="h1">LOGO</p> */}
        </Link>

        <nav className={showMediaIcons ? "navbar active" : "navbar"} ref={ref}>
          <ul className="menu">
            <li>
              <div className="dropdown">
                <Link to="" className="dropbtn d-flex drop-link" >
                  Business-Guide <MdArrowDropDown className="mt-1" />
                </Link>
                <div className="dropdown-content">
                  <li>
                    <Link to="/where" onClick={() => setShowMediaIcons(false)}>Where</Link>{" "}
                  </li>
                  <li>
                    <Link to="/how" onClick={() => setShowMediaIcons(false)}>How</Link>{" "}
                  </li>
                  <li>
                    <Link to="/why" onClick={() => setShowMediaIcons(false)}>Why</Link>{" "}
                  </li>
                </div>
              </div>
            </li>

            <li>
              <div className="dropdown">
                <Link to="/" className="dropbtn d-flex drop-link">
                  Licensing <MdArrowDropDown className="mt-1" />
                </Link>
                <div className="dropdown-content">
                  <li className="dropdown2">
                    <Link to="/mainland" className="d-flex">
                      Mainland License{" "}
                      <RiArrowDropRightFill fontSize="20px" className=" " />{" "}
                    </Link>
                    <ul className="dropdown-content2">

                      <li>
                        <Link to="/professional" onClick={() => setShowMediaIcons(false)}>Professional License</Link>{" "}
                      </li>
                      <li>
                        <Link to="/commericial" onClick={() => setShowMediaIcons(false)}>Commercial License</Link>{" "}
                      </li>
                      <li>
                        <Link to="/industrial" onClick={() => setShowMediaIcons(false)}>Industrial License</Link>{" "}
                      </li>
                      <li>
                        <Link to="/tourism" onClick={() => setShowMediaIcons(false)}>Tourism License</Link>{" "}
                      </li>
                      <li>
                        <Link to="/etrade" onClick={() => setShowMediaIcons(false)}>E Trader Lincense</Link>{" "}
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Link to="/freezone" onClick={() => setShowMediaIcons(false)}>Free zone License</Link>{" "}
                  </li>
                  <li>
                    <Link to="/offshore" onClick={() => setShowMediaIcons(false)}>Offshore License</Link>{" "}
                  </li>
                </div>
              </div>
            </li>
            <li>
              <div className="dropdown">
                <Link to="/" className="dropbtn d-flex drop-link" >
                  Visa <MdArrowDropDown className="mt-1" />
                </Link>
                <div className="dropdown-content">
                  <li>
                    <Link to="/businessvisa" onClick={() => setShowMediaIcons(false)}>Dubai-Business visa</Link>{" "}
                  </li>
                  <li>
                    <Link to="/investorvisa" onClick={() => setShowMediaIcons(false)}>Dubai-Investor visa</Link>{" "}
                  </li>
                </div>
              </div>
            </li>
            <li>
              <Link to="/about" className="drop-link" onClick={() => setShowMediaIcons(false)}>
                About US{" "}
              </Link>{" "}
            </li>
            <li>
              <Link to="/contact" className="drop-link" onClick={() => setShowMediaIcons(false)}>
                Contact Us{" "}
              </Link>{" "}
            </li>
            {/* 
            <li>
              <Link to="/adminpanel/aboutus" className="drop-link" onClick={() => setShowMediaIcons(false)}>
                Adminpanel
              </Link>
            </li> */}
          </ul>

          <AiOutlineClose
            className="display  mx-3"
            onClick={() => setShowMediaIcons(false)}
          />
        </nav>

        {/* <ChakkraModal /> */}

        <div className="hamburger-menu ">
          <Link onClick={() => setShowMediaIcons(!showMediaIcons)}>
            <GiHamburgerMenu className="icons " />
          </Link>
        </div>
      </section>

			<a href="https://wa.me/971529148888" className="float" target="_blank" id='whatsapp' rel="noreferrer">
				<AiOutlineWhatsApp className="fa fa-whatsapp my-float" />

			</a>
			<a href="tel:+971529148888" className="float2" target="_blank" id="phone" rel="noreferrer">
				<IoCallOutline className="fa fa-whatsapp my-float" />
			</a>
    </>
  );
};

export default Navbar;
