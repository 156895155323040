import React, { useEffect } from "react";
import img from "../../../Images/17.png";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  Box,
  AccordionIcon,
  AccordionPanel,
} from "@chakra-ui/react";

import Contact from "../../../components/Contact";
import SmallStrip from "../../../components/SmallStrip";
import ModalContact2 from "../../../components/ModalContact2";
import { Helmet } from "react-helmet";

const Professional = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
	useEffect(() => {
		// Add the GTM script dynamically to the head of the document
		const script = document.createElement('script');
		script.async = true;
		script.src = 'https://www.googletagmanager.com/gtag/js?id=G-4Y7FD8DFE9';
		document.head.appendChild(script);

		script.onload = () => {
			// Execute the inline script once the external script is loaded
			const inlineScript = document.createElement('script');
			inlineScript.type = 'text/javascript';
			inlineScript.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments)}
        gtag('js', new Date());
        gtag('config', 'G-4Y7FD8DFE9');
      `;
			document.head.appendChild(inlineScript);
		};

		return () => {
			// Cleanup scripts on component unmount
			document.head.removeChild(script);
		};
	}, []);
  return (
    <>
			<Helmet>
				<title>Professional License</title>
				<meta name="description" content="All license here" />
				<script async src={`https://www.googletagmanager.com/gtag/js?id=G-4Y7FD8DFE9`}></script>
				<script>
					{`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments)}
          gtag('js', new Date());
          gtag('config', 'G-4Y7FD8DFE9');
        `}
				</script>
			</Helmet>
			
      <SmallStrip data="Professional" data2="Licensing" />

      <div className="container-fluid padding3">
        <div className="row">
          <div className="col-md-6 col-lg-4 col-xl-4">
            <img src={img} alt="error" className="img-fluid rounded mt-2" />
          </div>
          <div className="col-md-6 col-lg-8 col-xl-8">
            <p className="heading-text-h1 bold-text color">
              Professional License in Dubai, UAE
            </p>
						<p className="para-small mt-2">
              Dubai is well-known for its luxurious lifestyle and being a hub for elite business professionals. The city has implemented numerous policies and procedures to support entrepreneurs in their journey to success. One of the most attractive features of investing in the UAE is its foreigner-friendly economic and fiscal protocols. Moreover, Dubai stands out in its ability to provide a secure and private business environment through a strong legal structure that ensures proper documentation for businesses of all sizes. To establish a company in Dubai, it is necessary to obtain a variety of licenses and permissions.
            </p>
						<p className="para-small mt-2">
							 For service-oriented businesses, a Professional trade license is mandatory, and the process of obtaining one can be quite challenging. However, Future Business SOLUTION (FBS) offers a cost-effective and hassle-free solution to this process. The Professional trade license is only awarded to those with appropriate educational qualifications and area of expertise, granting them complete ownership and all the benefits of a sole proprietorship. Although appointing a local Emirati service agent is mandatory to complete all legal formalities, they will not have any direct involvement or equity in the company. They are only required to provide their services for a fixed annual fee, and the liabilities of the company are not their responsibility. 
						</p>
						  <p className="para-small mt-2">
							FBS can assist in the application process for a Professional trade license from the Department of Economic Development (DED) in Dubai. This service can greatly benefit those seeking to establish their business in Dubai by streamlining the application process and reducing any legal complications.Dubai is well-known for its luxurious lifestyle and being a hub for elite business professionals. The city has implemented numerous policies and procedures to support entrepreneurs in their journey to success.
						</p>
            <br></br>
            <ModalContact2 />
          </div>
        </div>
      </div>

      <div className="container-fluid padding3 mt-2">
        <br></br>
        <div className="shadow">
          <Accordion defaultIndex={[0]} allowMultiple>
            <AccordionItem className="drop-para">
              <h2>
                <AccordionButton>
                  <Box
                    flex="1"
                    textAlign="left"
                    className="bold-text color-text font2"
                  >
                    How important is it to have a Professional License?
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4} className="para-small">
                In basic terms, a Professional License is designed for individuals or companies engaged in professional or service-oriented business activities. Acquiring a Professional License is a crucial step in establishing a professional firm, such as a tuition center or an art studio. Future Business Solutions (FBS) can assist you in obtaining a Professional License efficiently and at an affordable cost.
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem className="drop-para">
              <h2>
                <AccordionButton>
                  <Box
                    flex="1"
                    textAlign="left"
                    className="bold-text color-text font2"
                  >
                    What are the steps to obtain initial approval for a Professional License?
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4} className="para-small">
                In order to apply for a professional license in Dubai, the first step is to obtain initial approval. To do so, you must provide a precise description of your business idea. FBS can assist you with performing the official formalities in a systematic manner to help you obtain the initial approval from the DED. After obtaining initial approval, the next step is to find a compatible trade name that complies with government stipulations and reserve it. FBS can also help with this process to ensure that your chosen trade name meets all necessary requirements.
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem className="drop-para">
              <h2>
                <AccordionButton>
                  <Box
                    flex="1"
                    textAlign="left"
                    className="bold-text color-text font2"
                  >
                    What documents are necessary to obtain a professional license?
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4} className="para-small">
                To apply for a professional license, you need a filled application form, passport copy of the business owner, and naturalization book copy of the partner. Expatriate partners may need a sponsor's NOC if not on a tourist or visit visa. FBS will then submit these documents, along with any professional qualification and experience documents, to the DED.
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem className="drop-para">
              <h2>
                <AccordionButton>
                  <Box
                    flex="1"
                    textAlign="left"
                    className="bold-text color-text font2"
                  >
                    Does the application process for a professional license take a long time?
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4} className="para-small">
                FBS is an experienced entity in the field of company or service-related business setup, and can significantly shorten the application process for a professional license. They are adept at navigating the laws which may be unfamiliar to you, and can obtain the professional license quickly and efficiently with minimum effort, often in just a few weeks.
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem className="drop-para">
              <h2>
                <AccordionButton>
                  <Box
                    flex="1"
                    textAlign="left"
                    className="bold-text color-text font2"
                  >
                    How can FBS LLC assist with obtaining a professional license?
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4} className="para-small">
                Foreign entrepreneurs looking to establish a service-related business in mainland Dubai or elsewhere in the UAE must have a thorough understanding of the relevant laws in each location, as mainland laws differ from those in free zones and other areas. FBS can take on the heavy lifting required to help you achieve your goal.
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </div>
      </div>
      <br></br>
      <br></br>
      <Contact />
      <hr></hr>
    </>
  );
};

export default Professional;
