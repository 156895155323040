import React, { useEffect } from "react";
import img from "../../Images/5.png";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  Box,
  AccordionIcon,
  AccordionPanel,
} from "@chakra-ui/react";
import SmallStrip from "../../components/SmallStrip";
import ModalContact2 from "../../components/ModalContact2";
import { Helmet } from "react-helmet";

// import { AccordionIcon } from "@chakra-ui/icons";
const How = () => {

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 0);
  }, []);

	useEffect(() => {
		// Add the GTM script dynamically to the head of the document
		const script = document.createElement('script');
		script.async = true;
		script.src = 'https://www.googletagmanager.com/gtag/js?id=G-4Y7FD8DFE9';
		document.head.appendChild(script);

		script.onload = () => {
			// Execute the inline script once the external script is loaded
			const inlineScript = document.createElement('script');
			inlineScript.type = 'text/javascript';
			inlineScript.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments)}
        gtag('js', new Date());
        gtag('config', 'G-4Y7FD8DFE9');
      `;
			document.head.appendChild(inlineScript);
		};

		return () => {
			// Cleanup scripts on component unmount
			document.head.removeChild(script);
		};
	}, []);

  return (
    <>
			<Helmet>
				<title>How to Start Business in Dubai?</title>
				<meta name="description" content="All license here" />
				<script async src={`https://www.googletagmanager.com/gtag/js?id=G-4Y7FD8DFE9`}></script>
				<script>
					{`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments)}
          gtag('js', new Date());
          gtag('config', 'G-4Y7FD8DFE9');
        `}
				</script>
			</Helmet>
      <SmallStrip data="How" data2="Business-Guide" />
      <div className="container-fluid padding3">
        <div className="row">
          <div className="col-md-6 col-lg-4 col-xl-4">
            <img src={img} alt="error" className="img-fluid rounded mt-1" />
          </div>
          <div className="col-md-6 col-lg-8 col-xl-8">
            <p className="heading-text-h1 bold-text color">
              How to Start Business in Dubai?
            </p>
            <p className="para-small mt-2">
              Are you an aspiring entrepreneur looking to venture into trading, especially in a foreign country? FBS is here to help! Our platform offers an easy and efficient way for you to start trading, with our comprehensive suite of services making the entire process smoother and more seamless. With our extensive expertise in the field, we provide the best advice and guidance to make sure you get off on the right foot. 
						</p>
						<p className="para-small mt-2">
							 As consultants in the business setup arena, we maintain an easy-to-understand process that helps you to not just choose where in Dubai do you initiate your company like whether it is to be in the mainland or free zone but also the right activities you need to be involved in based on what you have in mind. FBS plans to be with you throughout all the required legal formalities inclusive of paperwork, getting business license and opening your corporate bank account.
						</p>
						 <p className="para-small mt-2">
						  Our initial consultation is as friendly as its professional and encourages you to ask us anything related to the entire process of firm setup and we also can help ensure that you are ready both mentally and fiscally before the processing begins. We also help in translation and legalization services, corporate PRO services as well as product registration. Let's take our time to study your project in detail and discuss all your requirements for corporate services. After that, we can create a specific plan of action to help you jump through the RFP hoops and get what you need so you can get back to work on whatever's most important to you growing your business.
						</p>
            <br></br>
            <ModalContact2 />
          </div>
        </div>
      </div>
      <br></br>
      <br></br>
      <br></br>
      <div>
      </div>




      <div className="container-fluid padding3 ">
        <p className="heading-text-h2 bold-text color">
          Some Fundamental Formalities when setting up a business in Dubai {" "}
        </p>
        <br></br>

        <div className="shadow">
          <Accordion defaultIndex={[0]} allowMultiple>
            <AccordionItem className="drop-para">
              <h2>
                <AccordionButton>
                  <Box
                    flex="1"
                    textAlign="left"
                    className="bold-text color-text font2"
                  >
                    Choosing the Right Company/Trade/Business Name
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4} className="para-small">
                In the UAE, a company name is quite important, and has a registration process for the same. Wealth Factory has the experience and expertise to set up your business in UAE or a free zone, whichever is the case. We offer company registration assistance from the Arab Emirates to any other country in the world providing easy company registration, company setup, offshore company establishment and other services.
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem className="drop-para">
              <h2>
                <AccordionButton>
                  <Box
                    flex="1"
                    textAlign="left"
                    className="bold-text color-text  font2"
                  >
                    Need for Local Partner for an Expat as a Business Requirement
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4} className="para-small">
                Businesses can consider cooperating with local partners that have knowledge and experience in UAE law. In this way, they can avoid many mistakes, unnecessary losses and costly mistakes. The law here is very clear, so it's a real plus to have a local sponsor in UAE .
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem className="drop-para">
              <h2>
                <AccordionButton>
                  <Box
                    flex="1"
                    textAlign="left"
                    className="bold-text color-text font2"
                  >
                    Hiring Process for your Company
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4} className="para-small">
                It is quite a challenging process that requires not just Labour law comprehension but working toward the process in a knowledgeable and sensible manner. When hiring, it’s essential to put together a standard work contract and be aware of the various stipulations based on the sort of employment contracts on offer.  Guidelines are different when hiring if your business operates on the mainland or is based within the free zone.
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem className="drop-para">
              <h2>
                <AccordionButton>
                  <Box
                    flex="1"
                    textAlign="left"
                    className="bold-text color-text font2"
                  >
                    UAE Branch Office Set-Up
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4} className="para-small">
                Setting up a business in UAE can be done in many ways, but methods to retain complete ownership while doing so are trickier. Hence opening the branch office to an already existing company is often a popular choice among immigrant entrepreneurs. FBS is able to help guide you through all the legal paperwork and hassles if this is how you plan to set up an office.
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem className="drop-para">
              <h2>
                <AccordionButton>
                  <Box
                    flex="1"
                    textAlign="left"
                    className="bold-text color-text font2"
                  >
                    Corporate Banking
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4} className="para-small">
                Corporate banking is one of the most significant formalities that must be completed when establishing a startup in Dubai. It may also be complicated to open a bank account on your own. To help manage the company's bank account once it has been established, FBS offers all the services a business may require, from beginning a bank account to keeping it.
              </AccordionPanel>
            </AccordionItem>

          </Accordion>
        </div>
      </div>
    </>
  );
};

export default How;
