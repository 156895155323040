import React from "react";

import Typical from "react-typical";
import bg from "../Images/bg04.jpeg";

const SmallStrip = (prop) => {
  return (
    <>
      <div className="container-fluid" style={{ padding: "0" }}>
        <img src={bg} alt="error" className=" back-img " />
        <div className="icon-social-right h5 bold-text">
          <p >
            <Typical
              loop={Infinity}
              className="mx-1"
              steps={[`Home > ${prop.data2} > ${prop.data} `, 1000]}
            />
          </p>
        </div>
      </div>
      <br></br>
      <br></br>
    </>
  );
};

export default SmallStrip;
