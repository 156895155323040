import React from "react";
import ReactDOMClient from "react-dom/client";
import "./index.css";
import App from "./App";

// 1. import `ChakraProvider` component
import { ChakraProvider } from "@chakra-ui/react";

const container = document.getElementById("root");
const root = ReactDOMClient.createRoot(container);

root.render(
  <React.StrictMode>

    <ChakraProvider>
      <App />
    </ChakraProvider>

  </React.StrictMode>
);
