import React, { useEffect } from "react";
import Contact from "../../components/Contact";
import Needs from "../../homecomponents/Needs";
import img from "../../Images/4.png";
import bg from "../../Images/bg04.jpeg";

import SmallStrip from "../../components/SmallStrip";
import ModalContact2 from "../../components/ModalContact2";
import Typical from "react-typical";
import { Helmet } from "react-helmet";


// import "../../style.css";

const Where = () => {

	useEffect(() => {
		setTimeout(() => {
			window.scrollTo(0, 0);
		}, 0);
	}, []);
	useEffect(() => {
		// Add the GTM script dynamically to the head of the document
		const script = document.createElement('script');
		script.async = true;
		script.src = 'https://www.googletagmanager.com/gtag/js?id=G-4Y7FD8DFE9';
		document.head.appendChild(script);

		script.onload = () => {
			// Execute the inline script once the external script is loaded
			const inlineScript = document.createElement('script');
			inlineScript.type = 'text/javascript';
			inlineScript.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments)}
        gtag('js', new Date());
        gtag('config', 'G-4Y7FD8DFE9');
      `;
			document.head.appendChild(inlineScript);
		};

		return () => {
			// Cleanup scripts on component unmount
			document.head.removeChild(script);
		};
	}, []);
	return (
		<>
			<Helmet>
				<title>Where to Start Business in Dubai?</title>
				<meta name="description" content="All license here" />
				<script async src={`https://www.googletagmanager.com/gtag/js?id=G-4Y7FD8DFE9`}></script>
				<script>
					{`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments)}
          gtag('js', new Date());
          gtag('config', 'G-4Y7FD8DFE9');
        `}
				</script>
			</Helmet>

			<SmallStrip data="Where" data2="Business-Guide" />

			{/* <div className="container-fluid" style={{ padding: "0", marginLeft: "-10px" }}>
        <img src={bg} alt="error" className=" back-img " />

        <div className="icon-social-right h5 bold-text">
          <p>
            <Typical
              loop={Infinity}
              className=""
              steps={[`Home > fsdfhjf > dsahhjdfhjl `, 1000]}
            />
          </p>
        </div>
      </div> */}


			<div className="container-fluid padding3">
				<div className="row">
					<div className="col-lg-4 col-md-6  col-xl-4">
						<img src={img} className="img-fluid rounded" alt="error" />
					</div>
					<div className="col-lg-8 col-md-6  col-xl-8">
						<p className="heading-text-h1 bold-text color">
							Where to Start Business in Dubai?
						</p>

						<p className="para-small mt-2">
							Dubai has been consistently ranked as one of the top cities in the world for business and investment. The strategic location of the UAE in the Middle East, coupled with its dynamism and openness, has cemented Dubai's reputation as a hub for trade, tourism and investment. In recent times, Dubai has transformed into an ultra-modern city, with a skyline adorned with some of the tallest and most iconic buildings in the world. This has further solidified its position as a destination of choice for businesses and individuals looking to create new opportunities and conquer new markets.
						</p>
						<p className="para-small mt-2">

							Dubai offers investors the flexibility to choose between two types of companies: mainland or free zone regional companies. Mainland companies can conduct business activities within the local market or even outside the UAE with no operational restrictions. These companies are required to obtain their trading and professional licenses from the Department of Economic Development (DED). On the other hand, free zone companies are designed for those who wish to conduct business activities solely inside the free zone or globally. stores a variety of options for setting up their businesses, including free zone and offshore companies. Free zone companies are allowed to operate only within the free zone or outside the UAE, while offshore companies are prohibited from conducting business within the country and therefore do not require a physical presence in the Emirates.
						</p>
					
						<p className="para-small mt-2">
							When deciding where to establish a business, it is essential to consider the location carefully. With its strategic location and thriving business environment, Dubai offers a unique opportunity to investors looking to explore new markets and create a global presence for their businesses.
							we can guide your choices based on what you have in mind and help you weigh all the options available to you to take your company to its full potential based on what Dubai has to offer.
						</p>
						<br></br>
						<ModalContact2 />
						<br></br>
					</div>
				</div>

			</div>
			<br></br>
			<br></br>

			{/* <Needs /> */}
			<br></br>




			{/* <div className="container-fluid padding3">
        <div className="row">
          <center>
            <p className="h2 bold-text color">Company Establishing Services</p>
          </center>
          <p className="mt-4">
            Is being a part of the business community in Dubai a dream of yours? We at future Business Solutions (FBS) can help you set up your enterprise in the most strategically favorable manner.
          </p>

          <div className="col-lg-4 col-md-6 col-xl-4 mt-4">
            <div className="container-photo">
              <img src={img1} alt="Avatar" className="image" />
              <div className="overlay">
                <div className="text">
                  {" "}
                  You may opt for any of the Free Zone regions listed below
                  based on your business endeavour.
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-xl-4 mt-4">
            <div className="container-photo">
              <img src={img1} alt="Avatar" className="image" />
              <div className="overlay">
                <div className="text">
                  {" "}
                  You may opt for any of the Free Zone regions listed below
                  based on your business endeavour.
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-xl-4 mt-4">
            <div className="container-photo">
              <img src={img1} alt="Avatar" className="image" />
              <div className="overlay">
                <div className="text">
                  {" "}
                  You may opt for any of the Free Zone regions listed below
                  based on your business endeavour.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
			<br></br>


			{/* <Contact /> */}

		</>
	);
};

export default Where;
