// import { initializeApp } from "firebase/app";
// import { getFirestore } from "firebase/firestore";
// import { getDatabase } from "firebase/database";

// const firebaseConfig = {
//   apiKey: "AIzaSyBHN9wM2O-ncfXh60LV-HSHoe-e3fjRmDA",
//   authDomain: "financial-app-82567.firebaseapp.com",
//   projectId: "financial-app-82567",
//   storageBucket: "financial-app-82567.appspot.com",
//   messagingSenderId: "311844851893",
//   appId: "1:311844851893:web:39d1c9908b15aed3ad6efc",
//   measurementId: "G-GKT9T1Q0D9",
// };

import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";


const firebaseConfig = {
  apiKey: "AIzaSyAIn-fe-F97Xn1bRqcF5FP1zksVPWkkC2E",
  authDomain: "fn2-real.firebaseapp.com",
  projectId: "fn2-real",
  storageBucket: "fn2-real.appspot.com",
  messagingSenderId: "237197514776",
  appId: "1:237197514776:web:382bd5ee8422428837cb6d",
  measurementId: "G-RR9ZJ5MJKE"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getDatabase(app);
export const store = getFirestore(app);
export const storage = getStorage(app);
export const auth = getAuth();