
import React, { useState } from "react";
import { uid } from 'uid';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';



import { store } from "../FirebaseConfig";
// import { uid } from "uid";
import { collection, doc, setDoc, serverTimestamp, getDocs, updateDoc } from "firebase/firestore";
import { useEffect } from "react";



import AboutSingle from "./components/AboutSingle";
const AboutUs = () => {

    const [data, setData] = useState({});
    const [singleBlog, setSingleBlog] = useState([]);

    const [lgShow, setLgShow] = useState(false);
    console.log(data)

    const handleAdd = async (e) => {

        e.preventDefault();

        const uuid = uid();
        try {
            const res = await setDoc(doc(store, "Aboutus", uuid), {
                ...data,
                timeStamp: serverTimestamp(),

            });
            console.log(res);
            window.location.reload();
            alert("Contact Submit successfully");

        } catch (error) {
            console.log(error);
        }
    };


    const handleInput = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setData({ ...data, [name]: value });
    };

    useEffect(() => {
        const fetchData = async () => {
            let list = [];
            try {
                const querySnapshot = await getDocs(collection(store, "Aboutus"));
                querySnapshot.forEach((doc) => {
                    list.push({ id: doc.id, ...doc.data() });
                });
                setSingleBlog(list);
                console.log("res", list);
            } catch (err) {
                console.log(err);
            }
        };
        fetchData();
    }, []);


    const handleUpdate = async (e, id) => {
        e.preventDefault();
        console.log(data)

        try {
            const res = await updateDoc(doc(store, "Aboutus", id), {
                ...data,
                timeStamp: serverTimestamp(),
            });
            console.log(res);

            alert("Contact Submit successfully");

        } catch (error) {
            console.log(error);
        }
    };

    return (
        <>

            <Modal
                size="xl"
                show={lgShow}
                scrollable={true}
                style={{ overflow: 'auto' }}
                onHide={() => setLgShow(false)}
                aria-labelledby="example-modal-sizes-title-xl"
            >
                <Modal.Body>
                    <form onSubmit={handleAdd}>
                        <div className="formInput mt-5" >
                            <input
                                name='heading'
                                placeholder="Heading add"
                                className="mx-2 inputfiled"

                                onChange={handleInput}
                                rows="4" cols="90"
                                style={{ border: "1px solid grey" }}
                            />
                        </div>
                        <div className="formInput mt-5" >
                            <textarea

                                name='discription'
                                className="mx-2 inputfiled"
                                placeholder="discription"

                                onChange={handleInput} rows="5" cols="100"
                                style={{ border: "1px solid grey" }}>

                            </textarea>
                        </div>
                        <div className="formInput mt-5" >
                            <textarea

                                name='information'
                                className="mx-2 inputfiled"
                                placeholder="information"

                                onChange={handleInput} rows="5" cols="100"
                                style={{ border: "1px solid grey" }}
                            >
                            </textarea>
                        </div>
                        <div className="formInput mt-5" >
                            <input
                                name="url"
                                type="url"
                                className="mx-2 inputfiled"
                                placeholder="https://example.com"
                                onChange={handleInput}
                                style={{ border: "1px solid grey" }}
                            >
                            </input>
                        </div>

                        <Button className="mx-1 mt-3 button-animation" type="submit">
                            Submit
                        </Button>
                    </form>
                </Modal.Body>
            </Modal>
            {/* <Button className="mx-1 mt-3 button-animation" onClick={() => setLgShow(true)} type="submit">
                Submit222
            </Button> */}
            <Modal
                size="xl"
                show={lgShow}
                scrollable={true}
                style={{ overflow: 'auto' }}
                onHide={() => setLgShow(false)}
                aria-labelledby="example-modal-sizes-title-xl"
            >
                <Modal.Body>
                    <form onSubmit={handleUpdate}>
                        <div className="formInput mt-5" >
                            <input
                                name='heading'
                                placeholder="Heading "
                                className="mx-2 inputfiled"
                                value={data.heading}
                                onChange={handleInput}
                                rows="4" cols="90"
                                style={{ border: "1px solid grey" }}
                            />
                        </div>
                        <div className="formInput mt-5" >
                            <textarea

                                name='discription'
                                className="mx-2 inputfiled"
                                placeholder="discription"
                                value={data.discription}
                                onChange={handleInput} rows="5" cols="100"
                                style={{ border: "1px solid grey" }}>

                            </textarea>
                        </div>
                        <div className="formInput mt-5" >
                            <textarea

                                name='information'
                                className="mx-2 inputfiled"
                                placeholder="information"
                                value={data.information}
                                onChange={handleInput} rows="5" cols="100"
                                style={{ border: "1px solid grey" }}
                            >
                            </textarea>
                        </div>
                        <div className="formInput mt-5" >
                            <input
                                name="url"
                                type="url"
                                className="mx-2 inputfiled"
                                placeholder="https://example.com"
                                onChange={handleInput}
                                style={{ border: "1px solid grey" }}
                            >
                            </input>
                        </div>


                        <Button style={{ background: "rgb(9 7 48)" }} className="mx-1 mt-3 text-white" type="submit">
                            Submit
                        </Button>
                    </form>
                </Modal.Body>
            </Modal>

            {/* <Modal isOpen={isOpen} size="xl" onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Add Blog</ModalHeader>
                    <hr></hr>
                    <ModalCloseButton />
                    <ModalBody>
                        <form onSubmit={handleAdd}>
                            <div className="formInput mt-5" >
                                <input
                                    name='heading'
                                    placeholder="Heading "
                                    className="mx-2 inputfiled"

                                    onChange={handleInput}
                                    rows="4" cols="90"
                                    style={{ border: "1px solid grey" }}
                                />
                            </div>
                            <div className="formInput mt-5" >
                                <textarea

                                    name='discription'
                                    className="mx-2 inputfiled"
                                    placeholder="discription"

                                    onChange={handleInput} rows="5" cols="100"
                                    style={{ border: "1px solid grey" }}>

                                </textarea>
                            </div>
                            <div className="formInput mt-5" >
                                <textarea

                                    name='information'
                                    className="mx-2 inputfiled"
                                    placeholder="information"

                                    onChange={handleInput} rows="5" cols="100"
                                    style={{ border: "1px solid grey" }}
                                >
                                </textarea>
                            </div>
                            <div className="formInput mt-5" >
                                <input
                                    name="url"
                                    type="url"
                                    className="mx-2 inputfiled"
                                    placeholder="https://example.com"
                                    onChange={handleInput}
                                    style={{ border: "1px solid grey" }}
                                >
                                </input>
                            </div>

                            <Button className="mx-1 mt-3 button-animation" type="submit">
                                Submit
                            </Button>
                        </form>
                    </ModalBody>


                </ModalContent>
            </Modal>

            <Modal isOpen={isOpen} size="xl" onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>update Blog</ModalHeader>
                    <hr></hr>
                    <ModalCloseButton />
                    <ModalBody>
                        <form onSubmit={handleUpdate}>
                            <div className="formInput mt-5" >
                                <input
                                    name='heading'
                                    placeholder="Heading "
                                    className="mx-2 inputfiled"
                                    value={data.heading}
                                    onChange={handleInput}
                                    rows="4" cols="90"
                                    style={{ border: "1px solid grey" }}
                                />
                            </div>
                            <div className="formInput mt-5" >
                                <textarea

                                    name='discription'
                                    className="mx-2 inputfiled"
                                    placeholder="discription"
                                    value={data.discription}
                                    onChange={handleInput} rows="5" cols="100"
                                    style={{ border: "1px solid grey" }}>

                                </textarea>
                            </div>
                            <div className="formInput mt-5" >
                                <textarea

                                    name='information'
                                    className="mx-2 inputfiled"
                                    placeholder="information"
                                    value={data.information}
                                    onChange={handleInput} rows="5" cols="100"
                                    style={{ border: "1px solid grey" }}
                                >
                                </textarea>
                            </div>
                            <div className="formInput mt-5" >
                                <input
                                    name="url"
                                    type="url"
                                    className="mx-2 inputfiled"
                                    placeholder="https://example.com"
                                    onChange={handleInput}
                                    style={{ border: "1px solid grey" }}
                                >
                                </input>
                            </div>


                            <Button style={{ background: "rgb(9 7 48)" }} className="mx-1 mt-3 text-white" type="submit">
                                Submit
                            </Button>
                        </form>
                    </ModalBody>
                </ModalContent>
            </Modal> */}


            {/* <Button onClick={onOpen} style={{ background: "rgb(9 7 48)" }} className="mx-1 text-white">Add Blog</Button> */}


            <div className="container-fluid">
                {singleBlog?.map((blog) => (
                    <>
                        <AboutSingle blog={blog} > </AboutSingle>
                        {/* <p>  {blog.heading}</p>
                    <p>  {blog.discription}</p>
                    <p>  {blog.information}</p>
                    <Button onClick={handleUpdate(blog.id)}>update</Button> */}
                    </>
                ))}
            </div>






        </>
    )
}

export default AboutUs
