import React, { useEffect } from "react";
import img from "../../Images/7.png";
import {
	Accordion,
	AccordionItem,
	AccordionButton,
	Box,
	AccordionIcon,
	AccordionPanel,
} from "@chakra-ui/react";

import Contact from "../../components/Contact";
import SmallStrip from "../../components/SmallStrip";
import ModalContact2 from "../../components/ModalContact2";
import { Helmet } from "react-helmet";

const InvestorVisa = () => {

	useEffect(() => {
		setTimeout(() => {
			window.scrollTo(0, 0);
		}, 0);
	}, []);
	useEffect(() => {
		// Add the GTM script dynamically to the head of the document
		const script = document.createElement('script');
		script.async = true;
		script.src = 'https://www.googletagmanager.com/gtag/js?id=G-4Y7FD8DFE9';
		document.head.appendChild(script);

		script.onload = () => {
			// Execute the inline script once the external script is loaded
			const inlineScript = document.createElement('script');
			inlineScript.type = 'text/javascript';
			inlineScript.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments)}
        gtag('js', new Date());
        gtag('config', 'G-4Y7FD8DFE9');
      `;
			document.head.appendChild(inlineScript);
		};

		return () => {
			// Cleanup scripts on component unmount
			document.head.removeChild(script);
		};
	}, []);

	return (
		<>
			<Helmet>
				<title>Investor Visa </title>
				<meta name="description" content="All license here" />
				<script async src={`https://www.googletagmanager.com/gtag/js?id=G-4Y7FD8DFE9`}></script>
				<script>
					{`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments)}
          gtag('js', new Date());
          gtag('config', 'G-4Y7FD8DFE9');
        `}
				</script>
			</Helmet>

			<SmallStrip data="Dubai-Investor-Visa" data2="Visa" />

			<div className="container-fluid padding3">
				<div className="row">
					<div className="col-md-6 col-lg-4 col-xl-4">
						<img src={img} alt="error" className="img-fluid rounded mt-1" />
					</div>
					<div className="col-md-6 col-lg-8 col-xl-8">
						<p className="heading-text-h1 bold-text mt-2 ">DUBAI INVESTOR VISA</p>
						<p className="para-small">
							While UAE, has a flourishing economy with a favorable business nurturing environment, Dubai is the emirate most people choose to finally pursue investment opportunities.  UAE presents such individuals with a chance to acquire a visa belonging to a different category – the Dubai Investor Visa. Anyone interested in either investing in an already existing business; or starting a venture of their own in the mainland or free zones can apply for this visa, also known as a Dubai partner visa. The visa validity often is dependent on the amount a person invests in the country, so the applicants who get it can also live in Dubai while they work with the added benefit of sponsoring family members.

						</p>
						<p className="para-small mt-2">
							The investor visa is also an aid used by the government to standardize its intake of immigrant investors in the UAE, and hence, you also have to give certain documents to the Dubai Immigration Department when applying for the same. Another advantage of having an investor status in Dubai is how much easier it gets to apply for visas to visit other GCC countries.
						</p>
						<p className="para-small  mt-2">
							We have noticed that clients who come to us are usually new to the process of starting a business or moving to the UAE and understandably apprehensive and clueless about the official processes involved. We at Future Business Solutions (FBS) want our clients to rest assured as we will be with them every step of the way and available for any queries whatsoever, they may have about Dubai Investor Visa.
						</p>
						<br></br>

						<ModalContact2 />
					</div>
				</div>
			</div>


			<div className="container-fluid padding3 ">
				<br></br>
				<div className="row shadow">

					<Accordion defaultIndex={[0]} allowMultiple>
						<AccordionItem className="drop-para">
							<h2>
								<AccordionButton>
									<Box
										flex="1"
										textAlign="left"
										className="bold-text font2 color-text"
									>
										HOW TO BECOME AN INVESTOR IN DUBAI?
									</Box>
									<AccordionIcon />
								</AccordionButton>
							</h2>
							<AccordionPanel pb={4} className="para-small">
								Future Business Solutions (FBS) offers uncomplicated processing for application to become an investor and to attain the much-coveted Dubai Investor Visa. We understand the industry, and the legal technicalities involved well enough to not just represent your interest but also to direct you in the right direction to become an investor as promptly and competently as possible in Dubai or any other emirate in the UAE.
							</AccordionPanel>
						</AccordionItem>

						<AccordionItem className="drop-para">
							<h2>
								<AccordionButton>
									<Box
										flex="1"
										textAlign="left"
										className="bold-text  font2 color-text"
									>
										ONCE I RECEIVE A SINGLE DUBAI INVESTOR VISA, CAN I GET A BUSINESS PARTNER?
									</Box>
									<AccordionIcon />
								</AccordionButton>
							</h2>
							<AccordionPanel pb={4} className="para-small">
								Yes, you can find a business partner once you have received a Dubai investor visa as several business partners are allowed to work under a single Investor Visa in Dubai. But the investment amount cannot be split among each of you and FBS recommends that each business partner has to invest a minimum of about AED 10 million.
							</AccordionPanel>
						</AccordionItem>
						<AccordionItem className="drop-para">
							<h2>
								<AccordionButton>
									<Box
										flex="1"
										textAlign="left"
										className="bold-text  font2 color-text"
									>
										WILL THE INVESTOR VISA APPLICATION PROCESS TAKE LONG?
									</Box>
									<AccordionIcon />
								</AccordionButton>
							</h2>
							<AccordionPanel pb={4} className="para-small" >
								Though the application process for an investor visa may extend to a period of about 20 days wherein they check the potential investor’s background and finally issue the Entry Permit. FBS helps to shorten the process, by making sure all the necessary documents are submitted at the right time and that all the rules and criteria are correctly met for a smooth application process.
							</AccordionPanel>
						</AccordionItem>

					</Accordion>


					<Accordion defaultIndex={[0]} allowMultiple>

						<AccordionItem className="drop-para">
							<h2>
								<AccordionButton>
									<Box
										flex="1"
										textAlign="left"
										className="bold-text  font2 color-text"
									>
										CAN THE DUBAI INVESTOR VISA BE EXTENDED TO INVOLVE DEPENDENTS?
									</Box>
									<AccordionIcon />
								</AccordionButton>
							</h2>
							<AccordionPanel pb={4} className="para-small">
								Major benefits of receiving the Dubai investor visa include sponsorship, wherein you bring your closest family members like your spouse, children, and parents to live with you with the added advantage of allowing them to work in the country if they should wish to do so. The visa can also be extended to an executive director and advisor from your company.
							</AccordionPanel>
						</AccordionItem>
						<AccordionItem className="drop-para">
							<h2>
								<AccordionButton>
									<Box
										flex="1"
										textAlign="left"
										className="bold-text  font2 color-text"
									>
										WHAT IS THE VALIDITY PERIOD FOR AN INVESTOR VISA IN DUBAI AND CAN I WORK WHEN I GET IT?
									</Box>
									<AccordionIcon />
								</AccordionButton>
							</h2>
							<AccordionPanel pb={4} className="para-small">
								The Investor Visa has a validity of about three years during which the holder of the visa can both live and work in Dubai or anywhere in the UAE. If you like the country and want to stay longer FBS recommends you apply for a 5-year or longer 10-year residency visa.
							</AccordionPanel>
						</AccordionItem>
					</Accordion>

				</div>

			</div>
			<br></br>
			<br></br>
			<br></br>

			<br></br>
			<Contact />
			<hr></hr>
		</>
	);
};

export default InvestorVisa;
