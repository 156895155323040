// import { useDisclosure } from '@chakra-ui/react';
import { doc, serverTimestamp, updateDoc } from 'firebase/firestore';

import React from 'react'
import { useState } from 'react';
import { Button } from 'react-bootstrap';
import { store } from '../../FirebaseConfig';

const AboutSingle = (blog) => {


    // const [singleBlog, setSingleBlog] = useState([]);
    // const { isOpen, onOpen, onClose } = useDisclosure()
    const { heading, information, discription, id } = blog.blog;

    const [data, setData] = useState({ heading, information, discription, id });

    // const fetchSingleData = async (id) => {

    //     try {
    //         const docRef = doc(store, "blog", id);
    //         const docSnap = await getDoc(docRef);
    //         if (docSnap.exists()) {
    //             setShow(true);
    //             setSingleBlog(docSnap.data());
    //         } else {
    //             // doc.data() will be undefined in this case
    //             console.log("No such document!");
    //         }
    //     } catch (error) {
    //         console.log(error)
    //     }

    // }

    const handleInput = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setData({ ...data, [name]: value });
    };

    const logOut = () => {
        localStorage.removeItem('user')
        window.location.reload();
    }

    const handleUpdate = async (e) => {
        e.preventDefault();
        console.log(data)

        try {
            const res = await updateDoc(doc(store, "Aboutus", id), {
                ...data,
                timeStamp: serverTimestamp(),
            });
            console.log(res);

            alert("Contact Submit successfully");

        } catch (error) {
            console.log(error);
        }
    };

    return (
        <div style={{ width: "100%" }} className="row">
            <div style={{ justifyContent: "space-between", display: "flex" }} >
                <h1 className='h2 mt-3'> About us</h1>
                <button style={{ background: "rgb(9 7 48)", paddingLeft: "1%", paddingRight: "1%", borderRadius: 5 }} className="mx-2 mt-3 text-white" onClick={logOut}>logOut</button>
            </div>

            <form onSubmit={handleUpdate}>
                <div className="formInput mt-5" >
                    <input
                        name='heading'
                        placeholder="Heading "
                        className="mx-1"
                        value={data.heading}
                        onChange={handleInput}
                        rows="4" cols="90"
                        style={{ border: "1px solid grey", borderRadius: 10, padding: "1%", }}
                    />
                </div>
                <div className="formInput mt-5" >
                    <textarea

                        name='discription'
                        // className="mx-2 inputfiled"
                        placeholder="discription"
                        value={data.discription}
                        onChange={handleInput}
                        className="mx-1"
                        style={{ border: "1px solid grey", width: "100%", height: "25rem", padding: "1%", borderRadius: 10 }}>

                    </textarea>
                </div>
                <div className="formInput mt-5" >
                    <textarea

                        name='information'
                        // className="mx-2 inputfiled"
                        placeholder="information"
                        value={data.information}
                        onChange={handleInput}
                        className="mx-1"
                        style={{ border: "1px solid grey", width: "100%", height: "25rem", padding: "1%", borderRadius: 10 }}
                    >
                    </textarea>
                </div>
                {/* <div className="formInput mt-5" >
                    <input
                        name="url"
                        type="url"
                        // className="mx-2 inputfiled"
                        placeholder="https://example.com"
                        onChange={handleInput}
                        className="mx-2"
                        style={{ border: "1px solid grey" }}
                    >
                    </input>
                </div> */}


                <Button style={{ background: "rgb(9 7 48)" }} className="mx-2 mt-3 text-white" type="submit">
                    Submit
                </Button>
            </form>
        </div>
    )
}

export default AboutSingle
