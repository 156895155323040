import { CheckCircle } from 'lucide-react'
import React from 'react'
import { AiFillFacebook, AiFillLinkedin, AiFillSkype, AiOutlineInstagram } from 'react-icons/ai';
import { Link, useNavigate } from 'react-router-dom'

const ThankYouPage = () => {

	const navigate = useNavigate();

	setTimeout(() => {
		navigate("/");
	}, 5000);

	return (
		<>

			<div className="thank-you-container">
				<center>
					<h1 className='mt-5'> <CheckCircle size={32} /></h1> </center>
				<h1 className='mt-4 font-thanks'>Thank You!</h1>
				<p style={{ marginTop: "1rem" }}>Thank you for showing your interest. We have successfully received your details. One of our business setup consultants will assist you shortly
				</p>


				<br></br>

				<h2 className='font-for-thanks'>	Visit Our Website</h2>
				<br></br>
				<Link to="/" style={{ padding: "0.5rem", backgroundColor:"green", color:'white', textDecoration: "none", borderRadius: '5px' }}  className=''>Visit Website</Link>
				<br></br>
				<br></br>
			</div>

			{/* <div className="thank-you-container-2 container" style={{ marginTop: "4rem" }}>
				<div style={{ textAlign: 'center' }}>
					<p className='font-for-thanks'> Connect With Us</p>
					<div>
						<li className="zoom marginFooter mt-3 d-flex">
							<a href="/" className="mt-1 ">
								{" "}

								<AiOutlineInstagram className="icon-social-small-2 " style={{ color: "#cd486b" }} />
							</a>
							<a href="/" className=" mt-1 ">
								{" "}

								<AiFillLinkedin className="icon-social-small-2 " style={{ color: "#0077b5" }} />
							</a>
							<a href="/" className="  mt-1 ">
								{" "}

								<AiFillFacebook className="icon-social-small-2 " style={{ color:"#316FF6"}} />
							</a>
					
						</li>
					</div>

				</div>


				<div style={{ textAlign: 'center' }}>
					<h2 className='font-for-thanks'>	Visit Our Website</h2>
					<br></br>
					<Link className=' button-effect-white4 fill5 '>Visit Website</Link>
				</div>
			</div> */}


		</>
	)
}

export default ThankYouPage