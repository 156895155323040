import { collection, getDocs } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import Contact from "../components/Contact";
import { store } from "../FirebaseConfig";
import image from "../Images/11.png"
import SmallStrip from "../components/SmallStrip";
import { Helmet } from "react-helmet";

const About = () => {

	useEffect(() => {
		setTimeout(() => {
			window.scrollTo(0, 0);
		}, 0);
	}, []);
	const [aboutUsInfo, setAboutUsInfo] = useState([]);


	useEffect(() => {
		const fetchData = async () => {
			let list = [];
			try {
				const querySnapshot = await getDocs(collection(store, "Aboutus"));
				querySnapshot.forEach((doc) => {
					list.push({ id: doc.id, ...doc.data() });
				});
				setAboutUsInfo(list);
				console.log("res", list);
			} catch (err) {
				console.log(err);
			}
		};
		fetchData();
	}, []);

	useEffect(() => {
		// Add the GTM script dynamically to the head of the document
		const script = document.createElement('script');
		script.async = true;
		script.src = 'https://www.googletagmanager.com/gtag/js?id=G-4Y7FD8DFE9';
		document.head.appendChild(script);

		script.onload = () => {
			// Execute the inline script once the external script is loaded
			const inlineScript = document.createElement('script');
			inlineScript.type = 'text/javascript';
			inlineScript.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments)}
        gtag('js', new Date());
        gtag('config', 'G-4Y7FD8DFE9');
      `;
			document.head.appendChild(inlineScript);
		};

		return () => {
			// Cleanup scripts on component unmount
			document.head.removeChild(script);
		};
	}, []);

	return (
		<>
			<Helmet>
				<title>About US</title>
				<meta name="description" content="All license here" />
				<script async src={`https://www.googletagmanager.com/gtag/js?id=G-4Y7FD8DFE9`}></script>
				<script>
					{`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments)}
          gtag('js', new Date());
          gtag('config', 'G-4Y7FD8DFE9');
        `}


				</script>


			</Helmet>

			<SmallStrip data="" data2="About us" />
			{aboutUsInfo.map((item) => (

				<>
					<div className="container-fluid padding3 ">

						<div className="row ">
							<div className="col-md-6 col-lg-4   col-xl-4">
								<img src={image} alt="error" className="img-fluid rounded mt-1" ></img>
							</div>
							<div className="col-md-6 col-lg-8 col-xl-8">
								<p className="heading-text-h1 bold-text mt-1"> {item.heading}</p>

								<p className="mt-1 para-small"> {item.discription}</p>
								{/* <p style={{ fontSize: "16px" }} className="mt-3"> {item.information}</p> */}
							</div>
						</div>
						<p style={{ fontSize: "15px" }} className="mt-3"> {item.information}</p>
					</div>

				</>
			))}
			<br></br>

			<Contact />
		</>
	);
};

export default About;
