import React, { useEffect } from "react";
import img from "../../../Images/16.png";
import {
	Accordion,
	AccordionItem,
	AccordionButton,
	Box,
	AccordionIcon,
	AccordionPanel,
} from "@chakra-ui/react";

import Contact from "../../../components/Contact";
import SmallStrip from "../../../components/SmallStrip";
import ModalContact2 from "../../../components/ModalContact2";
import { Helmet } from "react-helmet";

const Etrade = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	useEffect(() => {
		// Add the GTM script dynamically to the head of the document
		const script = document.createElement('script');
		script.async = true;
		script.src = 'https://www.googletagmanager.com/gtag/js?id=G-4Y7FD8DFE9';
		document.head.appendChild(script);

		script.onload = () => {
			// Execute the inline script once the external script is loaded
			const inlineScript = document.createElement('script');
			inlineScript.type = 'text/javascript';
			inlineScript.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments)}
        gtag('js', new Date());
        gtag('config', 'G-4Y7FD8DFE9');
      `;
			document.head.appendChild(inlineScript);
		};

		return () => {
			// Cleanup scripts on component unmount
			document.head.removeChild(script);
		};
	}, []);

	return (
		<>
			<Helmet>
				<title>Etrade License</title>
				<meta name="description" content="All license here" />
				<script async src={`https://www.googletagmanager.com/gtag/js?id=G-4Y7FD8DFE9`}></script>
				<script>
					{`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments)}
          gtag('js', new Date());
          gtag('config', 'G-4Y7FD8DFE9');
        `}
				</script>
			</Helmet>
			
			<SmallStrip data="Etrade" data2="Licensing" />

			<div className="container-fluid padding3">
				<div className="row">
					<div className="col-md-6 col-lg-4 col-xl-4">
						<img src={img} alt="error" className="img-fluid rounded mt-2" />
					</div>
					<div className="col-md-6 col-lg-8 col-xl-8">
						<p className="heading-text-h1 bold-text color">E Trader License in Dubai</p>
						<p className="para-small mt-2">
							Dubai has been known as a hub for entrepreneurs and small businesses. The Emirate has continuously strived to create an environment that is conducive to businesses, especially those starting up. The E-Trader license is a testament to this effort.
							The license has been received positively by stay-at-home entrepreneurs and freelancers who want to establish their businesses online. In addition to regulating home-based businesses, the E-Trader license has also boosted the confidence of customers who shop online. The license assures customers that they are buying authentic products from licensed businesses.

						</p>
						<p className="para-small mt-2">
							Apart from selling handmade products or services through social media accounts, the E-Trader license also allows entrepreneurs to create their websites and sell their products or services directly to customers. This way, entrepreneurs can build their brand and expand their customer base without the need for a physical office space. The process of obtaining an E-Trader license is straightforward, making it easy for aspiring entrepreneurs to apply.
						</p>

						<p className="para-small mt-2">
							The license is available for UAE or GCC nationals, as well as foreigners of certain other nationalities, who are over 21 years old and based in Dubai.
						</p>


						<br></br>

						<ModalContact2 />
					</div>
				</div>
			</div>
			<br></br>

			<div className="container-fluid padding3 ">
				<br></br>
				<div className="shadow">
					<Accordion defaultIndex={[0]} allowMultiple>
						<AccordionItem className="drop-para">
							<h2>
								<AccordionButton>
									<Box
										flex="1"
										textAlign="left"
										className="bold-text color-text font2"
									>
										WHAT IS THE IMPORTANCE OF AN E-TRADER LICENSE IN THE UAE?
									</Box>
									<AccordionIcon />
								</AccordionButton>
							</h2>
							<AccordionPanel pb={4} className="para-small">
								E-commerce has experienced exponential growth due to the widespread use of the internet and social media, creating opportunities for both investors and consumers. However, to prevent fraudulent activities and ensure a secure online shopping experience in Dubai, the Department of Economic Development (DED) has introduced the E-Trader license. Obtaining this license is crucial for individuals or companies engaged in e-commerce activities in the UAE. At FBS, we provide relevant information on the application process and the requirements for obtaining an E-Trader license to operate legally in the UAE.
							</AccordionPanel>
						</AccordionItem>

						<AccordionItem className="drop-para">
							<h2>
								<AccordionButton>
									<Box
										flex="1"
										textAlign="left"
										className="bold-text color-text font2"
									>
										WHAT ARE THE PERKS OF AN E-TRADER LICENSE IN DUBAI?
									</Box>
									<AccordionIcon />
								</AccordionButton>
							</h2>
							<AccordionPanel pb={4} className="para-small">
								Having an E-Trader license in Dubai offers numerous benefits for individuals or companies involved in e-commerce activities. Firstly, it enables them to legally promote and sell their products or services through social media platforms such as Instagram, Facebook, and personal websites. Additionally, it allows them to showcase their products and services on online marketplaces and participate in exhibitions and conferences.
								Moreover, obtaining an E-Trader license provides protection for one's trading name and other intellectual property, ensuring that others do not use them without permission. This license also facilitates the growth of home-based businesses and freelancers by allowing them to operate legally and professionally.

							</AccordionPanel>
						</AccordionItem>
						<AccordionItem className="drop-para">
							<h2>
								<AccordionButton>
									<Box
										flex="1"
										textAlign="left"
										className="bold-text color-text font2"
									>
										CAN YOU SELL PRODUCTS OUTSIDE OF DUBAI IF YOU HAVE AN E-TRADER LICENSE?
									</Box>
									<AccordionIcon />
								</AccordionButton>
							</h2>
							<AccordionPanel pb={4} className="para-small">
								If you have an E-trader license in Dubai, you are authorized to sell products and services within the emirate only. However, to sell outside of Dubai, you must first seek approval from the relevant Department of Economic Development (DED) in the respective emirate. This applies even if you plan to sell online to other emirates. It may also be necessary to obtain a separate E-trader license for each emirate where you wish to conduct business.
							</AccordionPanel>
						</AccordionItem>
						<AccordionItem className="drop-para">
							<h2>
								<AccordionButton>
									<Box
										flex="1"
										textAlign="left"
										className="bold-text color-text font2"
									>
										HOW OFTEN DO I NEED TO RENEW MY E-TRADER LICENSE?
									</Box>
									<AccordionIcon />
								</AccordionButton>
							</h2>
							<AccordionPanel pb={4} className="para-small">
								If you have an E-trader license in Dubai, you are authorized to sell products and services within the emirate only. However, to sell outside of Dubai, you must first seek approval from the relevant Department of Economic Development (DED) in the respective emirate. This applies even if you plan to sell online to other emirates. It may also be necessary to obtain a separate E-trader license for each emirate where you wish to conduct business.
							</AccordionPanel>
						</AccordionItem>
						<AccordionItem className="drop-para">
							<h2>
								<AccordionButton>
									<Box
										flex="1"
										textAlign="left"
										className="bold-text color-text font2"
									>
										HOW CAN FBS LLC HELP WITH APPLYING FOR AN E-TRADER LICENSE IN DUBAI?
									</Box>
									<AccordionIcon />
								</AccordionButton>
							</h2>
							<AccordionPanel pb={4} className="para-small">
								If you're interested in launching your e-commerce business legally in Dubai, FBS LLC can provide valuable assistance with the E-Trader license application process. Our team will make sure that the process is as smooth as possible with minimal time and effort required from you. With our help, you can expect to receive your E-Trader license promptly from the Department of Economic Development (DED) without any delays. Additionally, we offer consultation services to help you operate your business in the most profitable manner possible.
							</AccordionPanel>
						</AccordionItem>

					</Accordion>
				</div>
			</div>
			<br></br>
			<br></br>
			<br></br>

			<br></br>
			<Contact />
			<hr></hr>
		</>
	);
};

export default Etrade;
