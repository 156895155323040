import React, { useEffect } from "react";
import img from "../../Images/1.png";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  Box,
  AccordionIcon,
  AccordionPanel,
} from "@chakra-ui/react";

import Contact from "../../components/Contact";
import SmallStrip from "../../components/SmallStrip";
import ModalContact2 from "../../components/ModalContact2";

const Mainland = () => {

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 0);
  }, []);

	useEffect(() => {
		// Add the GTM script dynamically to the head of the document
		const script = document.createElement('script');
		script.async = true;
		script.src = 'https://www.googletagmanager.com/gtag/js?id=G-4Y7FD8DFE9';
		document.head.appendChild(script);

		script.onload = () => {
			// Execute the inline script once the external script is loaded
			const inlineScript = document.createElement('script');
			inlineScript.type = 'text/javascript';
			inlineScript.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments)}
        gtag('js', new Date());
        gtag('config', 'G-4Y7FD8DFE9');
      `;
			document.head.appendChild(inlineScript);
		};

		return () => {
			// Cleanup scripts on component unmount
			document.head.removeChild(script);
		};
	}, []);

  return (
    <>
      <SmallStrip data="Mainland" data2="Licensing" />

      <div className="container-fluid padding3">
        <div className="row">
          <div className="col-md-6 col-lg-4 col-xl-4">
            <img src={img} alt="error" className="img-fluid rounded " />
          </div>
          <div className="col-md-6 col-lg-8 col-xl-8">
            <p className="heading-text-h1 h3 bold-text ">Dubai Mainland License, UAE</p>
						<p className="para-small mt-2">
              Dubai Mainland company formation is the most profitable and lucrative option for those looking to invest or establish a company in Dubai. Companies formed on the mainland are authorized to operate within the UAE jurisdiction, including commercialized geographical areas. The Dubai Economic Department is the primary issuer of the much-coveted Dubai Mainland license, which is available to businesses that are professional, commercial, or a combination of the two.
            </p>
						<p className="para-small mt-2">
							 Obtaining a Dubai Mainland license is essential for businesses looking for flexibility and growth opportunities. It ensures that the business is operating in compliance with Dubai and UAE laws, providing clients with confidence in the legitimacy of the company. While Emirati shareholders were previously required for larger mainland businesses, most business ideas and branch openings now allow for complete foreign ownership. Choosing to invest in the mainland rather than the free zones provides the advantage of expanding business branches not only within Dubai but also across the UAE. 
						</p>
						<p className="para-small mt-2">
							 Mainland licensing typically includes Professional, Commercial, Industrial, Tourism, and E-Trader licenses. At Future Business Solutions (FBS) LLC, we can provide guidance on the most advantageous investment options, even for businesses that require multiple licensing norms. In conclusion, Dubai Mainland company formation is a wise investment choice for those seeking growth and flexibility. The Dubai Mainland license provides legitimacy, and the ability to extend business operations across the UAE is an added advantage. At FBS LLC, we offer expert guidance to ensure that your investment is maximized for profitability and success.
						</p>
            <br></br>
            <ModalContact2 />
          </div>
        </div>
      </div>
      <br></br>

      <div className="container-fluid padding3">
        <div className="row">
          <center>
            <p className="heading-text-h1 bold-text color">Mainland</p>
          </center>

          <p className="para-small">
            Mainland companies offer great investment opportunities and are highly rewarding. Unlike free zone companies, the workings of a mainland company are not restricted by geographical boundaries. One of the most popular destinations for budding entrepreneurs to set up a mainland company is Dubai, which is known for its business-friendly policies and as a major business hub. Foreign investors can enjoy complete ownership of their companies in Dubai, making it an ideal location for setting up a business.
          </p>

          {/* <div className="col-lg-4 col-md-6 col-xl-4 mt-4">
            <img src={img1} className="img-fluid" alt="error" />
          </div>
          <div className="col-lg-4 col-md-6 col-xl-4 mt-4">
            <img src={img1} className="img-fluid" alt="error" />
          </div>
          <div className="col-lg-4 col-md-6 col-xl-4 mt-4">
            <img src={img1} className="img-fluid" alt="error" />
          </div> */}

        </div>
      </div>
      <br></br>

      <div className="container-fluid padding3 ">
        <br></br>
        <div className="row shadow" >


          <Accordion defaultIndex={[0]} allowMultiple>
            <AccordionItem className="drop-para">
              <h2>
                <AccordionButton>
                  <Box
                    flex="1"
                    textAlign="left"
                    className="bold-text color-text font2"
                  >
                    WHAT IS THE RELEVANCE OF A DUBAI MAINLAND COMPANY?
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4} className="para-small">
                Dubai mainland companies are of great importance to businesses looking to establish a foothold in the vibrant emirate. These companies, also known as onshore companies, are registered with the Dubai Department of Economic Development, and enable businesses to operate within the mainland area of Dubai, which is the most advanced and economically significant part of the emirate.
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem className="drop-para">
              <h2>
                <AccordionButton>
                  <Box
                    flex="1"
                    textAlign="left"
                    className="bold-text  color-text font2"
                  >
                    WHAT ARE THE ESSENTIAL STEPS WHEN APPLYING FOR A DUBAI MAINLAND COMPANY LICENSE?
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4} className="para-small">
                To obtain a mainland company license in Dubai, several government approvals are necessary. The following are the critical steps involved in obtaining approval: selecting the business activity and trade name, obtaining an initial certificate for the mainland company setup, notarizing the documents by the court, annotating the associative team, selecting an appropriate office space location, fulfilling tenancy requirements, submitting the final approval letter, and integrating all the necessary documents.
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem className="drop-para">
              <h2>
                <AccordionButton>
                  <Box
                    flex="1"
                    textAlign="left"
                    className="bold-text  color-text font2"
                  >
                    IN A MAINLAND BUSINESS, HOW MANY VISAS CAN BE OBTAINED?
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4} className="para-small">
                Future Business Solutions (FBS) ensures you are aware of exactly how many visas you can apply for when opening a business in mainland Dubai. While there are no direct restrictions against the number of visas but every 9 sqm of office space allows the allotment of about one visa. Therefore, the bigger the office space more the visas your company may receive.
              </AccordionPanel>
            </AccordionItem>

          </Accordion>


          <Accordion defaultIndex={[0]} allowMultiple>

            <AccordionItem className="drop-para">
              <h2>
                <AccordionButton>
                  <Box
                    flex="1"
                    textAlign="left"
                    className="bold-text  color-text font2"
                  >
                    WHAT ARE SOME GOOD LOCATIONS FOR A MAINLAND COMPANY SETUP?
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4} className="para-small">
                When it comes to starting a new business in Dubai, location plays a crucial role in its success. Fortunately, FBS can help identify suitable locations based on your business activity. Although many prominent places in Dubai offer financial advantages, such as Sheikh Zayed Road and Business Bay, it's essential to consider other factors as well. Deira, Jumeirah, and Al Qusais are among the areas that already have a booming business presence, making them potential launching pads for your new venture.
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem className="drop-para">
              <h2>
                <AccordionButton>
                  <Box
                    flex="1"
                    textAlign="left"
                    className="bold-text  color-text font2"
                  >
                    HOW CAN FBS LLC HELP WITH THE MAINLAND LICENSING OF THE COMPANY?
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4} className="para-small">
                Foreign entrepreneurs are often advised to set up their company as a mainland type in Dubai to take advantage of being a part of the thriving business community within the city. As a result, there is an increased demand for services that assist individuals in setting up and maintaining their businesses within Dubai. FBS is one such company that offers a range of services to help companies obtain their business trade licenses in Dubai. These services not only save time and cut costs but also ensure that the license is obtained in compliance with all relevant government regulations.


              </AccordionPanel>
            </AccordionItem>
            <br></br>
          </Accordion>

          <br></br>
        </div>



      </div>
      <br></br>


      {/* <div className="container-fluid ">
        <div className="row">
          <div className="col-lg-8 col-md-8 col-sm-12">


            <p className="h2 bold-text color">Frequently Asked Questions</p>

            <p>
              Mainland companies are the best and most rewarding of investments and company setups. Workings of the mainland company are not restricted in any manner as compared to a free zone company’s geographical boundaries.
            </p>
          </div>
        </div>
        <br></br>
        <div className="row">

          <div className="col-lg-4 col-md-6 col-xl-4 mt-4">
            <p className="bold-text">WHAT IS THE RELEVANCE OF A DUBAI MAINLAND COMPANY?</p>
            <p>The mainland company often referred to as an onshore company is a company registered at the Department of Economic Development in Dubai. Thereby availing a trouble-free trading experience in Dubai free of unnecessary taxing. Also, the location for the office space is easy as there are no geographical boundaries for the same. Recent Companies Law amendments allow foreigners a chance to own the complete mainland business. </p>
          </div>
          <div className="col-lg-4 col-md-6 col-xl-4 mt-4">
            <p className="bold-text">WHAT ARE THE ESSENTIAL STEPS WHEN APPLYING FOR A DUBAI MAINLAND COMPANY LICENSE?</p>
            <p>For a Dubai mainland company license, many government organization approvals are required. The essential steps that seek approval include the selection of business activity and trade name, an initial certificate for mainland company setup, notarization by the court, annotation of the associative team, choice of office space location and tenancy requirements, final submission letter, and assimilation of documents. </p>
          </div>
          <div className="col-lg-4 col-md-6 col-xl-4 mt-4">
            <p className="bold-text">IN A MAINLAND BUSINESS, HOW MANY VISAS CAN BE OBTAINED?</p>
            <p>Future Business Solutions (FBS) ensures you are aware of exactly how many visas you can apply for when opening a business in mainland Dubai. While there are no direct restrictions against the number of visas but every 9 sqm of office space allows the allotment of about one visa. Therefore, the bigger the office space more the visas your company may receive.  </p>
          </div>
          <div className="col-lg-4 col-md-6 col-xl-4 mt-4">
            <p className="bold-text">WHAT ARE SOME GOOD LOCATIONS FOR A MAINLAND COMPANY SETUP?</p>
            <p>FBS helps you identify apt locations based on your choice of business activity. While many prominent places in Dubai are financially advantageous Sheikh Zayed Road, Deira, Business Bay, Jumeirah, and Al Qusais all have the presence of already booming business places that may help launch your company in the right manner.  Zero restrictions to the locality give you a plethora of reasonable choices. </p>
          </div>
          <div className="col-lg-4 col-md-6 col-xl-4 mt-4">
            <p className="bold-text">HOW CAN FBS LLC HELP WITH THE MAINLAND LICENSING OF THE COMPANY?</p>
            <p>Most foreign entrepreneurs are often advised to set up their company as a mainland type in Dubai to take advantage of being a part of the business community within the city. As part of obtaining the business trade license in Dubai, FBS helps save time, cut costs when possible, and make sure the Dubai Economic Department requirements are observed correctly.  </p>
          </div>


        </div>



      </div> */}
      <br></br>


      <Contact />
      <hr></hr>
    </>
  );
};

export default Mainland;
