import React, { useRef, useState } from "react";
import "./modal.css";
// import {
//   Modal,
//   ModalOverlay,
//   ModalContent,
//   ModalFooter,
//   ModalBody,
//   ModalCloseButton,
//   useDisclosure,
//   Button,
// } from "@chakra-ui/react";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import boy from "../Images/popup-boy.jpg";
import { db } from "../FirebaseConfig";
import { uid } from "uid";
import { set, ref } from "firebase/database";
import { Form, Alert, InputGroup } from "react-bootstrap";

import emailjs from "@emailjs/browser";
import PhoneInput from "react-phone-input-international";
import { useNavigate } from "react-router-dom";

const Popup = () => {
	// const { isOpen, onOpen, onClose } = useDisclosure();
	const [name, setName] = useState("");
	const [lastname, setLastname] = useState("");
	const [phone, setPhone] = useState("");
	const [email, setEmail] = useState("");
	const [lgShow, setLgShow] = useState(true);
	const [mess, setMess] = useState("");
	const [message, setMessage] = useState({ error: false, msg: "" });
	const navigate = useNavigate()
	const form = useRef();
	// service_mvqum2y
	// template_8iujjgi
	// QHySjFGWk8XDFywh4eobl
// futurebusiness46@gmail.com

	const sendEmail = (e) => {
		e.preventDefault();

		emailjs
			.sendForm(
				"service_mvqum2y",
				"template_8iujjgi",
				form.current,
				"G-TZUKW8g_6IYTxKa"
			)
			.then(
				(result) => {
					console.log(result.text);

					window.location.reload();
				},
				(error) => {
					console.log(error);
				}
			);
	};

	const handleSubmit = async (e) => {
		const uuid = uid();
		e.preventDefault();
		setMessage("");
		if (
			name === "" ||
			lastname === "" ||
			phone === "" ||
			email === "" ||
			mess === ""
		) {
			setMessage({ error: true, msg: "All fields are mandatory!" });
			return;
		} else {
			set(ref(db, "users/" + uuid), {
				name: name,
				lastname: lastname,
				phone: phone,
				email: email,
				mess: mess,
			});

			setMessage({ error: false, msg: "Contact Submit successfully" });

			navigate('/thank-you')
		}

		setName("");
		setLastname("");
		setPhone("");
		setEmail("");
		setMess("");
	};

	const handleSubmitData = (e) => {
		e.preventDefault();
		if (
			name === "" ||
			lastname === "" ||
			phone === "" ||
			email === "" ||
			mess === ""
		) {
			setMessage({ error: true, msg: "All fields are mandatory!" });
			return;
		}
		handleSubmit(e);
		sendEmail(e);
	};



	return (
		<>
			{/* <Button onClick={() => setLgShow(true)} className="button-effect-white3  fill4">Contact us</Button> */}

			<Modal
				size="xl"
				show={lgShow}
				scrollable={true}
				style={{ overflow: 'auto'}}
				className="margin-top-modal"
				onHide={() => setLgShow(false)}
				aria-labelledby="example-modal-sizes-title-xl"
			>
				{/* <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-xl">
            Large Modal
          </Modal.Title>
        </Modal.Header> */}
				<Modal.Body> <div className="row">
					{/* <div className="col-md-6 col-lg-6">
            <img src={boy} className="img-fluid" alt="error" />
          </div> */}
					<div
						className="col-md-12 col-lg-12 mt-1 back-img-contactus text-dark mx-1"
						style={{ paddingLeft: "0px", paddingRight: "20px" }}
					>
						{message?.msg && (
							<Alert
								variant={message?.error ? "danger" : "success"}
								dismissible
								onClose={() => setMessage("")}
							>
								{message?.msg}
							</Alert>
						)}
						<Form ref={form} onSubmit={handleSubmitData}>
							{/* <div className="row text-center">
                <p>
                  Hassle-free company formation in Dubai Fill in your
                  <br></br>
                  details for a free consultation
                </p>
              </div> */}

							<div className="row">
								<div className="row center">
									<p className="h2 color-text bold-text">Contact Us</p>
								</div>
								<div className="col-md-6 mt-1">
									<Form.Group className="input-fluid" controlId="formName">
										{/* <p className="mx-2 bold-text">FirstName:</p> */}
										<InputGroup className=" mt-2">
											<Form.Control
												type="text"
												name="name"
												placeholder="FirstName"
												className="form-control"
												value={name}
												onChange={(e) => setName(e.target.value)}
											/>
										</InputGroup>
									</Form.Group>
								</div>
								<div className="col-md-6 mt-1">
									<Form.Group className="input-fluid" controlId="formlast">
										{/* <p className="mx-2 bold-text">LastName:</p> */}
										<InputGroup className="mt-2">
											<Form.Control
												type="text"
												name="lastname"
												placeholder="LastName"
												className="form-control"
												value={lastname}
												onChange={(e) => setLastname(e.target.value)}
											/>
										</InputGroup>
									</Form.Group>
								</div>
							</div>
							<div className="row ">
								<div className="col-md-6 mt-1">
									<Form.Group className="input-fluid" controlId="formPhone">
										{/* <p className="mx-2 bold-text">Phone:</p> */}
										<InputGroup className="mt-2 mx-1">
											<PhoneInput
												country={'ae'}
												inputProps={{
													name: 'phone',
													required: true,

												}}
												containerStyle={{

													marginLeft: "20px",
													borderRadius: "10px"
												}}
												inputStyle={{
													borderRadius: "25px",
													height: "3rem",
													width: "99%"
												}}
												name="phone"

												value={phone}
												style={{ width: "100%" }}
												onChange={event => setPhone(event)}
											/>
											{/* <Form.Control
                        type="number"
                        placeholder="phone"
                        name="phone"
                        className="form-control"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                      /> */}
										</InputGroup>
									</Form.Group>
								</div>
								<div className="col-md-6 mt-1">
									<Form.Group className="input-fluid" controlId="formEmail">
										{/* <p className="mx-2 bold-text">Email:</p> */}
										<InputGroup className="mt-2">
											<Form.Control
												type="email"
												placeholder="Email"
												name="email"
												className="form-control"
												value={email}
												onChange={(e) => setEmail(e.target.value)}
											/>
										</InputGroup>
									</Form.Group>
								</div>
							</div>
							<div className="row mt-2">
								<div className="col-md-12 ">
									<Form.Group
										className="input-fluid"
										controlId="formMessage"
									>
										{/* <p className="mx-2 bold-text">Message:</p> */}
										<InputGroup className=" mt-2">
											<Form.Control
												type="text"
												name="mess"
												placeholder="Messasge"
												className="form-control"
												value={mess}
												onChange={(e) => setMess(e.target.value)}
											/>
										</InputGroup>
									</Form.Group>
								</div>
							</div>
							<div className="row  mt-3 text-dark">
								<p>
									<input
										type="checkbox"
										name=""
										value=""
										className="mx-2"
									/>
									By checking this box , I understand and acknowledge that
									the information shared above is genuine to the best of my
									knowledge.
								</p>

							</div>
							<div className=" just-con   mt-3">
								<Button
									variant="primary"
									type="Submit"
									className="button-effect-white3  fill4"
								>
									SUBMIT
								</Button>
								<Button
									onClick={() => setLgShow(false)}
									className="button-effect-white3  fill4"
								>
									Close
								</Button>
							</div>
						</Form>
					</div>
				</div></Modal.Body>
			</Modal>

			{/* <Button onClick={onOpen} className="button-effect-white3  fill4">
        CONTACT US
      </Button>
      <Modal onClose={onClose} isOpen={isOpen} size="6xl" isCentered>
        <ModalOverlay />
        <ModalContent style={{ marginTop: "10rem" }}>
          <ModalCloseButton />
          <ModalBody style={{ padding: "0px" }}>
            <div className="row">
              <div className="col-md-6 col-lg-6">
                <img src={boy} className="img-fluid" alt="error" />
              </div>
              <div
                className="col-md-6 col-lg-6 mt-5"
                style={{ paddingLeft: "0px", paddingRight: "20px" }}
              >
                {message?.msg && (
                  <Alert
                    variant={message?.error ? "danger" : "success"}
                    dismissible
                    onClose={() => setMessage("")}
                  >
                    {message?.msg}
                  </Alert>
                )}
                <Form ref={form} onSubmit={handleSubmitData}>
                  <div className="row text-center">
                    <p>
                      Hassle-free company formation in Dubai Fill in your
                      <br></br>
                      details for a free consultation
                    </p>
                  </div>
                  <br></br>
                  <div className="row ">
                    <div className="col-md-6 mt-2">
                      <Form.Group className="input-fluid" controlId="formName">
                        <p className="mx-2">FirstName:</p>
                        <InputGroup className=" mt-2">
                          <Form.Control
                            type="text"
                            name="name"
                            placeholder=""
                            className="form-control"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                          />
                        </InputGroup>
                      </Form.Group>
                    </div>
                    <div className="col-md-6 mt-2">
                      <Form.Group className="input-fluid" controlId="formlast">
                        <p className="mx-2">LastName:</p>
                        <InputGroup className="mt-2">
                          <Form.Control
                            type="text"
                            name="lastname"
                            placeholder=""
                            className="form-control"
                            value={lastname}
                            onChange={(e) => setLastname(e.target.value)}
                          />
                        </InputGroup>
                      </Form.Group>
                    </div>
                  </div>
                  <div className="row ">
                    <div className="col-md-6 mt-2">
                      <Form.Group className="input-fluid" controlId="formPhone">
                        <p className="mx-2">Phone:</p>
                        <InputGroup className="mt-2">
                          <Form.Control
                            type="number"
                            placeholder=""
                            name="phone"
                            className="form-control"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                          />
                        </InputGroup>
                      </Form.Group>
                    </div>
                    <div className="col-md-6 mt-2">
                      <Form.Group className="input-fluid" controlId="formEmail">
                        <p className="mx-2">Email:</p>
                        <InputGroup className="mt-2">
                          <Form.Control
                            type="email"
                            placeholder=""
                            name="email"
                            className="form-control"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </InputGroup>
                      </Form.Group>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-md-12 mt-2">
                      <Form.Group
                        className="input-fluid"
                        controlId="formMessage"
                      >
                        <p className="mx-2">Message:</p>
                        <InputGroup className=" mt-2">
                          <Form.Control
                            type="text"
                            name="mess"
                            placeholder=""
                            className="form-control"
                            value={mess}
                            onChange={(e) => setMess(e.target.value)}
                          />
                        </InputGroup>
                      </Form.Group>
                    </div>
                  </div>
                  <div className="row  mt-5 ">
                    <p>
                      <input
                        type="checkbox"
                        name=""
                        value=""
                        className="mx-2"
                      />
                      By checking this box , I understand and acknowledge that
                      the information shared above is genuine to the best of my
                      knowledge.
                    </p>

                    <br></br>
                  </div>

                  <div className=" just-con   mt-5">
                    <Button
                      variant="primary"
                      type="Submit"
                      className="button-effect-white3  fill4"
                    >
                      SUBMIT
                    </Button>
                    <Button
                      onClick={onClose}
                      className="button-effect-white3  fill4"
                    >
                      Close
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
          </ModalBody>
        </ModalContent>
      </Modal> */}
		</>
	);
};

export default Popup;
