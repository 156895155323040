import React, { useEffect, useState } from "react";
import "./caursal.css";
import AOS from "aos";
import "aos/dist/aos.css"; //
import banner from "../Images/banner05.png";
import Typical from "react-typical";
import { BsBuilding } from "react-icons/bs";
import { Link } from "react-router-dom";
import banner1 from "../Images/small-banner.png";

const Caursol = () => {
  useEffect(() => {
    AOS.init();
  }, []);
	const [screenWidth, setScreenWidth] = useState(window.innerWidth);

	useEffect(() => {
		// Update the screen width state on window resize
		const handleResize = () => {
			setScreenWidth(window.innerWidth);
		};

		window.addEventListener('resize', handleResize);

		// Clean up the event listener on component unmount
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	const isMobileView = () => {
		return screenWidth < 498;
	};

  return (
    <>
      <div className="container-banner">
				{isMobileView() ? (
							<img src={banner1} alt="error" className="img-fluid " style={{height : '430px'}}/>
				
				) : (
						<img src={banner} alt="error" className="img-fluid img-size" />
				)}

        
        <div className="div-banner">
          {" "}
          <div>
						<p className="d-flex display-600-min" style={{ fontSize: "13px" }}><BsBuilding className="icon-banner" />Unleashing the Potential with Dynamic Solutions</p>
            <p className="text-white font-size-slider bold-text d-flex">
							<BsBuilding className=" icon-banner display-500" />
              BUSINESS SETUP
              IN DUBAI
            </p>
          </div>
          <hr className="color-hr2" style={{ marginBottom: "5px" }} size="4px"></hr>
          {/* <p className="text-small-banner1">2022</p> */}

					<p className="text-small-banner2 display-500">
            <Typical
              loop={Infinity}
              className="text-small-banner2 bold-text"
              steps={[
                " HOW TO START YOUR COMPANY IN DUBAI",
                1000,
                "START NEW JOURNEY WITH US",
                1000,
              ]}
            />
          </p>
					<p className="text-small-banner3 size-1 display-500">
            Are you dreaming of starting your own company in Dubai or the UAE, but don't know where to start? Let our expertise in the field take your dreams to new heights and help turn them into reality
          </p>
					<div className="margin-top">

					<Link to="/contact" className="button-effect-white4 fill5 ">
						Request your free consultation
					</Link>
						</div>

          <div className="d-flex margin-top" >
            <Link to="/mainland" style={{ color: "white" }} className="text-small-banner2 mx-1  ">
              MAINLAND |
            </Link>
            <Link to="/offshore" style={{ color: "white" }} className="text-small-banner2 mx-1  ">
              OFFSHORE |
            </Link>
            <Link to="/freezone" style={{ color: "white" }} className="text-small-banner2 mx-1">
              FREEZONE
            </Link>
            {/* <p className="text-small-banner2 mx-1  bold-text">MAINLAND | </p>
            <p className="text-small-banner2 mx-1  bold-text">OFFSHORE | </p>
            <p className="text-small-banner2 mx-1 bold-text">FREEZONE  </p> */}
          </div>
        </div>

      </div>

      <br></br>
      <br></br>

    </>
  );
};

export default Caursol;
