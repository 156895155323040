import React, { useEffect } from "react";
import img from "../../Images/3.png";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  Box,
  AccordionIcon,
  AccordionPanel,
} from "@chakra-ui/react";

import img1 from "../../Images/b4.png";
import Contact from "../../components/Contact";
import SmallStrip from "../../components/SmallStrip";
import ModalContact2 from "../../components/ModalContact2";
import { Helmet } from "react-helmet";
const OffShore = () => {

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 0);
  }, []);
	useEffect(() => {
		// Add the GTM script dynamically to the head of the document
		const script = document.createElement('script');
		script.async = true;
		script.src = 'https://www.googletagmanager.com/gtag/js?id=G-4Y7FD8DFE9';
		document.head.appendChild(script);

		script.onload = () => {
			// Execute the inline script once the external script is loaded
			const inlineScript = document.createElement('script');
			inlineScript.type = 'text/javascript';
			inlineScript.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments)}
        gtag('js', new Date());
        gtag('config', 'G-4Y7FD8DFE9');
      `;
			document.head.appendChild(inlineScript);
		};

		return () => {
			// Cleanup scripts on component unmount
			document.head.removeChild(script);
		};
	}, []);

  return (
    <>
			<Helmet>
				<title>OffShore</title>
				<meta name="description" content="All license here" />
				<script async src={`https://www.googletagmanager.com/gtag/js?id=G-4Y7FD8DFE9`}></script>
				<script>
					{`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments)}
          gtag('js', new Date());
          gtag('config', 'G-4Y7FD8DFE9');
        `}
				</script>
			</Helmet>
      <SmallStrip data="OffShore" data2="Licensing" />

      <div className="container-fluid padding3">
        <div className="row">
          <div className="col-md-6 col-lg-4 col-xl-4" >
            <center>

              <img src={img} alt="error" className="img-fluid rounded" />
            </center>
          </div>
          <div className="col-md-6 col-lg-8 col-xl-8" >

            <p className="heading-text-h1 bold-text color">
              Offshore Company Formation in Dubai,UAE
            </p>

						<p className="para-small mt-2">
              Dubai has been a popular destination for entrepreneurs and investors due to its favorable business environment. The emirate's strategic location, thriving economy, and attractive tax policies make it a desirable location to start or invest in a business. One notable feature of Dubai's business landscape is its openness to all types of businesses, whether they require a traditional, commercial, industrial, or professional license, or a more specific setup.
            </p>
						<p className="para-small mt-2">
							However, setting up an offshore company in Dubai can be complex and requires in-depth knowledge of UAE's customs, laws, and regulations. This is where Future Business Solutions (FBS) comes in. We help interested entrepreneurs make the right choices based on their needs and budget. We ensure that all license and visa applications have all necessary documents in order and are free from errors or omissions that could lead to delays or rejection from the government.
						</p>

						<p className="para-small mt-2">
							At FBS, we understand that time is of the essence when it comes to setting up a business. That's why we work efficiently to help our clients start trading as soon as possible. We assist with all necessary applications and communicate with the relevant departments and authorities to ensure a smooth process. We even offer to help set up an offshore bank account once our clients have started trading.
							In summary, Dubai's favorable business environment, particularly its free zones, makes it a desirable destination for entrepreneurs and investors.
						</p>

            <br></br>
            <ModalContact2 />
          </div>
        </div>
        {/* <div className="row mt-4">

          <div className="col-md-6 col-lg-6 col-xl-9" style={{ padding: "0px" }}>

            <p className="para-small"> However, setting up an offshore company can be complex, which is why FBS offers services to help entrepreneurs make the right choices and start trading as soon as possible. International entrepreneurs are particularly drawn to Dubai's free zones, such as the Jebel Ali Free Zone (JAFZA) and the Ras Al Khaimah Free Trade Zone (RAK FTZ), due to the benefits they offer. These free zones allow for complete tax exemption, no currency restrictions, and 100% recovery of both capital and profit. Some free zones even offer offshore trading options, which can be appealing for certain businesses.However, setting up an offshore company in Dubai can be complex and requires in-depth knowledge of UAE's customs, laws, and regulations. This is where Future Business Solutions (FBS) comes in. We help interested entrepreneurs make the right choices based on their needs and budget. We ensure that all license and visa applications have all necessary documents in order and are free from errors or omissions that could lead to delays or rejection from the government.At FBS, we understand that time is of the essence when it comes to setting up a business. That's why we work efficiently to help our clients start trading as soon as possible. We assist with all necessary applications and communicate with the relevant departments and authorities to ensure a smooth process. We even offer to help set up an offshore bank account once our clients have started trading.In summary, Dubai's favorable business environment, particularly its free zones, makes it a desirable destination for entrepreneurs and investors. However, setting up an offshore company can be complex, which is why FBS offers services to help entrepreneurs make the right choices and start trading as soon as possible.</p>

          </div>
          <div className="col-md-6 col-lg-6 col-xl-3" >

            <img src={img} alt="error" className="img-fluid rounded" />

          </div>
        </div> */}




      </div>
      <br></br>


      <div className="container-fluid padding3">
        <div className="row">
          <center>
            <p className="heading-text-h1 bold-text color">
              Offshore
            </p>
            <p className="para-small mt-2">
              Investing in an offshore company can be a highly rewarding option, especially for those who prioritize flexibility and lack of ties to a particular country. Offshore companies offer several advantages over their onshore counterparts, such as the absence of legal restrictions or tax requirements in the UAE. Additionally, owning an offshore company allows the owner to conduct business from their home country, eliminating the need to be physically present in the UAE for extended periods, as is required for mainland companies.
            </p>

          </center>
          <br></br>

          {/* <div className="col-lg-4 col-md-6 col-xl-4 mt-4">
            <img src={img1} className="img-fluid" alt="error" />
          </div>
          <div className="col-lg-4 col-md-6 col-xl-4 mt-4">
            <img src={img1} className="img-fluid" alt="error" />
          </div>
          <div className="col-lg-4 col-md-6 col-xl-4 mt-4">
            <img src={img1} className="img-fluid" alt="error" />
          </div>
          <br></br> */}

        </div>
      </div>
      <div className="container-fluid padding3 mt-3">
        <br></br>
        <div className="shadow">
          <Accordion defaultIndex={[0]} allowMultiple>
            <AccordionItem className="drop-para">
              <h2>
                <AccordionButton>
                  <Box
                    flex="1"
                    textAlign="left"
                    className="bold-text color-text font2"
                  >
                    HOW IS A DUBAI OFFSHORE COMPANY DIFFERENT FROM A FREE ZONE SETUP?
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4} className="para-small">
                The key distinctions between a Dubai offshore company and a Dubai free zone company are as follows: Firstly, a Dubai offshore company is prohibited from having a physical presence in the UAE and cannot engage in any business activities within the country. Secondly, individuals who are shareholders or owners of offshore companies are not eligible to apply for residency visas in the UAE. In contrast, a Dubai free zone company is permitted to operate within the UAE and establish a physical office in a designated free zone area. Furthermore, free zone companies are eligible to apply for residency visas for their shareholders or owners.
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem className="drop-para">
              <h2>
                <AccordionButton>
                  <Box
                    flex="1"
                    textAlign="left"
                    className="bold-text color-text font2"
                  >
                    HAT ARE THE BENEFITS OF A DUBAI OFFSHORE COMPANY SETUP?
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4} className="para-small">
                One of the significant advantages of setting up a Dubai offshore company is the confidentiality it provides to its investors as the personal information of its owners and shareholders are not publicly available. Additionally, offshore companies in Dubai benefit from the UAE's low tax regime, with zero taxes imposed on both corporate and personal income. Offshore companies are not required to pay share capital, which makes them an affordable investment option for many businesses.
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem className="drop-para">
              <h2>
                <AccordionButton>
                  <Box
                    flex="1"
                    textAlign="left"
                    className="bold-text color-text font2"
                  >
                    What business activities are allowed for offshore companies in Dubai?
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4} className="para-small">
                Offshore companies are permitted to engage in certain activities, including holding stakes in other businesses, managing real estate investments, and providing consultancy or advisory services. Some types of brokerage services are also allowed. For a comprehensive list of permitted activities, it is recommended to seek guidance from a reputable firm like FBS, who can provide detailed information and assist in selecting the activity that best suits your investment interests and capital.
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem className="drop-para">
              <h2>
                <AccordionButton>
                  <Box
                    flex="1"
                    textAlign="left"
                    className="bold-text color-text font2"
                  >
                    What are the essential documents required to set up an offshore company in Dubai?
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4} className="para-small">
                Along with the application for an offshore license, the following documents are essential:<br></br>

                1.	A copy of a valid passport for each shareholder of the company.<br></br>
                2.	Bank statements covering at least the past six months.<br></br>
                3.	Attested financial documents.<br></br>

                There may be additional documents required, and legal formalities to navigate, which can be managed by professional firms such as FBS. They can provide guidance and assistance with the entire process of setting up an offshore company in Dubai.

              </AccordionPanel>
            </AccordionItem>
            <AccordionItem className="drop-para">
              <h2>
                <AccordionButton>
                  <Box
                    flex="1"
                    textAlign="left"
                    className="bold-text color-text font2"
                  >
                    How can FBS LLC assist with the offshore license application in the UAE?
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4} className="para-small">
                Our highly skilled team at FBS can provide comprehensive support with offshore company formation and license application. One of the key benefits of working with us is that we offer customized solutions based on the specific details provided, such as the desired business activities and budget. Our team can guide you through the entire process, ensuring that all requirements are met, and the application is submitted accurately and promptly.
              </AccordionPanel>
            </AccordionItem>

          </Accordion>
        </div>
      </div>
      <br></br>
      <br></br>

      <Contact />
      <hr></hr>
    </>
  );
};

export default OffShore;
