import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css"; //
import {  BsBank2 } from "react-icons/bs";
// import { BiBuilding, BiBuildings } from "react-icons/bi";
import { TbCertificate } from "react-icons/tb";
import { FcProcess } from "react-icons/fc";
import { Link } from "react-router-dom";
import b4 from "../Images/b4.jpeg";
// import avatar from "../Images/avatar.jpg";
import office from "../Images/12.png";
import "./services.css";
import { FaShieldAlt, FaUsers, FaUserShield } from "react-icons/fa";
import { IoBusinessSharp } from "react-icons/io5";

import { RiServiceFill } from "react-icons/ri";

import { GiTakeMyMoney } from "react-icons/gi";
import { AiFillBank, AiOutlineFileProtect } from "react-icons/ai";

import img from "../Images/15.png";
import img1 from "../Images/23.png";
import img2 from "../Images/1.png";

// import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
// import { Keyboard, Pagination, Navigation } from "swiper";
import ModalContact2 from "../components/ModalContact2";

const Services = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <>
      <div className="container-fluid padding3">
        {" "}
        <div className="center">
          <p className="color2 heading-text-h1 bold-text">What Services We Offers</p>
          <p className=" mt-2 para-small">
            Are you looking to set up a business in Dubai? Starting a business
						in Dubai is easy with help from the right professionals. Future Business Solutions
            offers the best and economical business setup services
          </p>
          <p className="bold-text heading-text-h2 mt-2 text-secondary">
            Choose your business need in Dubai or UAE to continue
          </p>
        </div>

        <div className="row">
          <div className="col-lg-4 mt-3">
            {" "}
            <div className="card-small-2 zoom" style={{ height: "100%" }} >
              <div className="center">
                <br></br>
                <center>
                  <IoBusinessSharp className="color-text" fontSize="90px" />
                </center>
                <br></br>

                <h3 className=" h5 bold-text heading-text-h3">Company Registration & licensing</h3>
                <p className="para-small mt-1">
                  FBS ensures an easy issuance of the license for the production and trading of products made within the UAE or internationally
                </p>
                <br></br>
                {/* <br></br>
                <ModalContact2 />
                <br></br> */}
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-sm-12 mt-3">
            {" "}
            <div className="card-small-2 zoom" style={{ height: "100%" }}>
              <div className="center">
                <br></br>
                <center>
                  <FcProcess fontSize="90px" />
                </center>
                <br></br>

                <h3 className="bold-text heading-text-h3">Visa Services</h3>
                <p className="para-small mt-1">
                  FBS helps you apply for a Dubai Business Visa with extended support till you have established your capitalistic ventures successfully.
                </p>
                <br></br>
                {/* <br></br>
                <ModalContact2 />
                <br></br> */}
              </div>
            </div>
          </div>
          <div className="col-lg-4 mt-3">
            <div className="card-small-2 zoom" style={{ height: "100%" }}>
              <div className="center" >
                <br></br>
                <center>
                  <TbCertificate fontSize="90px" color="#8D3DAF" />
                </center>
                <br></br>

                <h3 className="heading-text-h3 bold-text">PRO Services</h3>

                <p className="para-small mt-1 mx-2">
                  we take pride in alleviating your business challenges and helping you achieve success by delivering exceptional
                  results with efficiency and affordability.
                </p>
                <br></br>
                {/* <br></br>
                <ModalContact2 />
                <br></br>
                <br></br> */}

              </div>
            </div>
          </div>
        </div>
        <div className="row">

          <div className="col-lg-4 mt-3">
            <div className="card-small-2 zoom" style={{ height: "100%" }}>
              <div className="center mx-2">
                <br></br>
                <center>
                  <AiFillBank className="color-text" fontSize="90px" />
                </center>
                <br></br>

                <h3 className="heading-text-h3 bold-text">Banking Assistance</h3>
                <p className="para-small mt-1">
                  FBS provides comprehensive banking services that cover everything from opening corporate accounts to managing them
                  efficiently.
                </p>
                <br></br>
                {/* <br></br>
                <ModalContact2 />
                <br></br>
                <br></br> */}
                <br></br>
              </div>
            </div>
          </div>
          <div className="col-lg-4 mt-3">
            <div className="card-small-2 zoom" style={{ height: "100%" }}>
              <div className="center">
                <br></br>
                <center>
                  <AiOutlineFileProtect className="color-text" fontSize="90px" />
                </center>
                <br></br>

                <h3 className="heading-text-h3 bold-text">Investor Rights Protection</h3>
                <p className="para-small mt-1">
                  We'll assist you in decision-making by understanding your vision and exploring all possibilities in Dubai to help your
                  company reach its fullest potential.
                </p>
                <br></br>
                {/* <br></br>
                <ModalContact2 />
                <br></br>
                <br></br> */}

              </div>
            </div>
          </div>
          <div className="col-lg-4 mt-3">
            <div className="card-small-2 zoom" style={{ height: "100%" }}>
              <div className="center mx-2">
                <br></br>
                <center>
                  <RiServiceFill className="color-text" fontSize="90px" />{" "}
                </center>
                <br></br>
                <h3 className="heading-text-h3 bold-text">Value-Added Services</h3>

                <p className="para-small mt-1">

                  We offer value-added services to new business such as business centers,recruitment service,advertising services etc.
                </p>
                <br></br>
                {/* <br></br>
                <ModalContact2 />
                <br></br> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid mt-5">
        <div className="row center color4">
          <div className="padding">
            <h1 className="text-white heading-text-h1 ">That is why we’re here.</h1>

            <p className="text-white para-small mt-2" style={{ fontWeight: "lighter" }}>
              We make it simpler and easier for you. Before you do anything,
              <br></br>
              talk to one of our Company Formation Specialists.
            </p>
            <br></br>
            <ModalContact2 />
          </div>
        </div>
      </div>

      <div className="container">


        <div className="row">
          <div className="col-lg-6 col-xl-4 col-md-6 col-sm-12  mt-3 center">
            {/* <center>
              <div className="box">
                <div className="imgBox">
                  <div className="back-img-3">
                    <h1 className="heading h1"> FREEZONE </h1>
                    <center>
                      <h1 className="display h1">
                        {" "}
                        <BsArrowDownRightSquare />
                      </h1>
                    </center>
                  </div>
                </div>
                <div className="content shadow">
                  <h2 className="bold-text d-flex">
                    <BsBuilding
                      fontSize="40px"
                      className="mx-2 bold-text"
                      color="purple"
                    />
                    FREEZONE LICENSE
                    <br />
                  </h2>
                </div>
              </div>
            </center> */}
            <Link to="/mainland">
              <div className="container-photo">
                <img src={img} alt="Avatar" className="img-fluid rounded" />
                <h1 className="middle-image-text heading-text-h1-big text-white bold-text" style={{}}>
                  MAINLAND LICENSE{" "}
                </h1>
                <div className="overlay">
                  <div className="text">
                    {" "}
										Mainland- A Dubai mainland company offers tax advantages, unrestricted office space, and allows foreigners full ownership.
                    {/* <Link to="/mainland" className="button-effect-white3  fill4  mt-4">
                      Know More
                    </Link> */}
                  </div>

                </div>
              </div>
            </Link>
          </div>
          <div className="col-lg-6 col-xl-4 col-md-6 col-sm-12   mt-3 center">
            {/* <center>
              <div className="box">
                <div className="imgBox">
                  <div className="back-img-2">
                    <h1 className="heading h1" style={{}}>
                      MAINLAND{" "}
                    </h1>
                    <center>
                      <h1 className="display h1">
                        {" "}
                        <BsArrowDownRightSquare />
                      </h1>
                    </center>
                  </div>
                </div>
                <div className="content shadow">
                  <h2 className="bold-text d-flex">
                    <BiBuildings
                      fontSize="40px"
                      className="mx-2 bold-text"
                      color="purple"
                    />
                    MAINLAND LICENSE
                    <br />
                  </h2>
                </div>
              </div>
            </center> */}
            <Link to="/freezone">
              <div className="container-photo">
                <img src={img1} alt="Avatar" className="img-fluid rounded" />
                <h1 className="middle-image-text heading-text-h1-big text-white bold-text" style={{}}>
                  FREEZONE LICENSE{" "}
                </h1>
                <div className="overlay">
                  <div className="text">
                    {" "}
										Freezone - Dubai Freezone Licenses enable businesses to operate in designated areas with specific licenses for professional, industrial, general trading, warehousing, and commercial activities.
                    {/* <Link to="/freezone" className="button-effect-white3  fill4  mt-4">
                      Know More
                    </Link> */}
                  </div>
                </div>
              </div>
            </Link>
          </div>
          <div className="col-lg-6 col-xl-4 col-md-6 col-sm-12   mt-3 center">
            {/* <center>
              <div className="box">
                <div className="imgBox">
                  <div className="back-img-1">
                    <h1 className="heading h1">OFFSHORE </h1>
                    <center>
                      <h1 className="display h1">
                        {" "}
                        <BsArrowDownRightSquare />
                      </h1>
                    </center>
                  </div>
                </div>
                <div className="content shadow">
                  <h2 className="bold-text d-flex">
                    <BiBuilding
                      fontSize="40px"
                      className="mx-2 "
                      color="purple"
                    />{" "}
                    OFFSHORE LICENSE
                    <br />
                  </h2>

                </div>
              </div>
            </center> */}
            <Link to="/offshore">
              <div className="container-photo ">
                <img src={img2} alt="Avatar" className="img-fluid rounded " />
                <h1 className="middle-image-text heading-text-h1-big text-white bold-text" style={{}}>
                  OFFSHORE LICENSE{" "}
                </h1>
                <div className="overlay">
                  <div className="text">
                    {" "}
										Offshore - Dubai offshore offers favorable tax and regulatory benefits for international businesses, making it an attractive destination for establishing offshore companies and conducting financial activities.
                  </div>
                  {/* <Link to="/offshore" className="button-effect-white3 text  fill4  mt-4">
                  Know More
                </Link> */}
                </div>
              </div>
            </Link>
          </div>

          <br></br>
          <br></br>
        </div>
      </div>

      <br></br>

      <div className="container-fluid padding3">
        <div className="row">
          <div className="col-lg-6 col-md-6">
            <br></br>
            <h1 className="color2 heading-text-h1" style={{ fontWeight: "bold" }}>
              Business Establishment in Dubai
            </h1>
            <p className=" para-small mt-2" >
              Dubai is widely recognized as the top destination for starting a business in the Middle East. Countless small businesses have blossomed into major players with a presence on the global stage thanks to the business-friendly environment and supportive federal laws. As an expat looking to establish a company in a foreign land, you've come to the right place.<br></br>  
            </p>
						<p className=" para-small mt-2" >
							The UAE's laws are welcoming to foreigners and offer an ideal environment for successful business ventures. If you're looking to become a UAE resident, setting up a mainland company is a great option. However, for complete ownership and zero taxation, a free zone or offshore company may be more suitable.
						</p>
						<p className=" para-small mt-2" >
							Whatever your choice may be, we at FBS would be honored to be a part of your venture from inception to completion. With our support, you can confidently navigate the process and realize your entrepreneurial aspirations in Dubai or the UAE.
						</p>
            <br></br>

            <ModalContact2 />
          </div>

          <div className="col-lg-6 col-md-6">
            <br></br>

            <img src={b4} className="img-fluid rounded" alt="error" />
          </div>
        </div>
        <br></br>


      </div>
      <div className="container-fluid padding3 mt-3" >
        <div className="row">
          <div className="col-lg-2 col-md-4 col-6  center zoom mt-2 color-hover">
            <center>
              <TbCertificate className="icon-service" />
            </center>
            <h4 className="text-secondary heading-text-h2" style={{ marginTop: "-1rem" }} >TRADE</h4>
            <h3 className="bold-text color-hover heading-text-h1 mt-1">LICENSE</h3>
          </div>
          <div className="col-lg-2 col-md-4 col-6  center zoom mt-2 color-hover">
            <center>
              <FaUserShield className="icon-service" />
            </center>
            <h4 className="text-secondary heading-text-h2" style={{ marginTop: "-1rem" }}>FIND A</h4>
            <h3 className="bold-text color-hover heading-text-h1 mt-1">SPONSOR</h3>
          </div>
          <div className="col-lg-2 col-md-4 col-6  center zoom mt-2 color-hover">
            <center>
              <GiTakeMyMoney className="icon-service" />
            </center>
            <h4 className="text-secondary heading-text-h2" style={{ marginTop: "-1rem" }}>COMPANY</h4>
            <h3 className="bold-text h4 heading-text-h1 mt-1">LIQUIDATION</h3>
          </div>
          <div className="col-lg-2 col-md-4 col-6  center zoom mt-2 color-hover">
            <center>
              <FaShieldAlt className="icon-service" />
            </center>
            <h4 className="text-secondary heading-text-h2" style={{ marginTop: "-1rem" }}>BRAND</h4>
            <h3 className="bold-text color-hover heading-text-h1 mt-1 ">PROTECTION</h3>
          </div>
          <div className="col-lg-2 col-md-4 col-6  center zoom  mt-2 color-hover">
            <center>
              <BsBank2 className="icon-service" />
            </center>
            <h5 className="text-secondary heading-text-h2" style={{ marginTop: "-1rem" }}> BANK ACCOUNT</h5>
            <h3 className="bold-text color-hover heading-text-h1 mt-1">OPENING</h3>
          </div>
          <div className="col-lg-2 col-md-4 col-6  center zoom  mt-2 color-hover">
            <center>
              <FaUsers className="icon-service" />
            </center>
            <h4 className="text-secondary heading-text-h2" style={{ marginTop: "-1rem" }}> PRO</h4>
            <h3 className="bold-text color-hover heading-text-h1 mt-1">SERVICES</h3>
          </div>

        </div>

      </div>


      <br></br>
      <br></br>


      <div className="back-img-5">
        <p className="heading-text-h1  mx-1  bold-text">Business Setup Guide</p>
        <br></br>
        <div className="row ">
          <div
            className="col-lg-4 col-md-4 mt-2"
          // data-aos="fade-up-right"
          // data-aos-duration="3000"
          >
            <div className="back zoom ">
              <div className="border-class">
                <h2 className="bold-text heading-text-h1">Why ?</h2>


                <h5 className="mt-3  heading-text-h2">
                  Dubai has undergone a steady metamorphosis from a Petroleum
                  dependent economy to an innovation and
                  technology-driven........
                </h5>

                <br />
                <Link to="/why" className="para-small link-dec">
                  Know more <span className="display-2 para-small">{">>>>"} </span>{" "}
                </Link>
              </div>
            </div>
          </div>
          <div
            className="col-lg-4 col-md-4 mt-2"
          // data-aos="fade-up"
          // data-aos-duration="3000"
          >
            <div className="back zoom">
              <div className="border-class">
                <h2 className="bold-text heading-text-h1">Where ?</h2>
                <br></br>

                <h5 className=" heading-text-h2">
                  You have a plethora of business investment opportunities in
                  Dubai. One of the major prerequisites that every investor
                  or..........
                </h5>
                <br></br>

                <Link className="para-small link-dec" to="/where">
                  Know more <span className="display-2 para-small">{">>>>"} </span>{" "}
                </Link>
              </div>
            </div>
          </div>
          <div
            className="col-lg-4 col-md-4 mt-2"
          // data-aos="fade-up-left"
          // data-aos-duration="3000"
          >
            <div className="back zoom">
              <div className="border-class">
                <h2 className="bold-text heading-text-h1">How ?</h2>
                <br></br>

                <h5 className="h5 heading-text-h2">
                  Both economic and political stability plays a vital role in
                  the overall progress of any region or country. Dubai has a
                  very professional...........
                </h5>
                <br></br>

                <Link to="/how" className="para-small link-dec">
                  Know more <span className="display-2 para-small">{">>>>"} </span>{" "}
                </Link>
              </div>
            </div>
          </div>
        </div>
        <br></br>
      </div>

      <br ></br>


      <div className="container-fluid padding3">
        <div className="row">
          <div
            className="col-lg-4 "
          // data-aos="fade-up-right"
          // data-aos-duration="3000"
          >

            <img src={office} alt="error" className="img-fluid rounded zoom " />
          </div>
          <div className="col-lg-8">
            <h1 className="color2 bold-text heading-text-h1 mt-2">Office Spaces</h1>

            <p className=" para-small mt-3">
              If you're looking to set up a business in the UAE, it's important to note that most businesses are required to have an office space to register and conduct their operations. However, there are exceptions to this rule, such as offshore companies and those operating under an E-Trader license. Depending on the type of business, office space requirements can vary. Onshore (or Mainland) offices are traditional office spaces that companies can either lease or buy. Shared offices are another option for onshore businesses looking for a more affordable option. 
            </p>
				
						<p className=" para-small mt-3">
							Free zone offices are available for companies operating within the respective free zone, while Flexi-desks provide a more cost-effective solution. It's common to be unsure of what office requirements apply to your company, and that's where Future Business Solutions (FBS) comes in. We can help you find an office that suits your specific needs and budget, providing the support you need to establish a successful business in the UAE.
						</p>
            <br></br>
            <ModalContact2 />
          </div>
        </div>
      </div>

      <br></br>
      <br></br>

      {/* <div className="container-fluid padding2">
        <div className="mx-5">
          <h1 className="bold-text  h1">Testimonials</h1>
          <br></br>
          <h5 className="h5 ">
            We regard the client testimonial as a treasured token of
            appreciation that signifies our value as a Business Setup service
            provider. As you all know credible testimonials build “Trust” – one
            of the core business virtues that we cherish.
          </h5>
        </div>
        <br></br>
        <br></br>
        <br></br>
        <Swiper
          spaceBetween={30}
          keyboard={{
            enabled: true,
          }}
          pagination={{
            clickable: true,
          }}
          navigation={true}
          modules={[Keyboard, Pagination, Navigation]}
          breakpoints={{
            576: {
              // width: 576,
              slidesPerView: 1,
            },
            768: {
              // width: 768,
              slidesPerView: 2,
            },
          }}
          name="mySwiper"
          className="mx-5"
        >
          <SwiperSlide>
            <div
              className="center3 hover-underline-animation "
              style={{
                paddingLeft: "10%",
                paddingRight: "10%",
                paddingTop: "3%",
                paddingBottom: "0%",
              }}
            >
              <p className=" ">
                “Spend a lot of time talking to customers face-to-face. You’d be
                amazed how many companies don’t listen to their customers.”
                “Spend a lot of time talking to customers face-to-face. You’d be
                amazed how many companies don’t listen to their customers.”
              </p>
              <br></br>
              <div className="d-flex">
                <img src={avatar} alt="error" className="avatar" />
                <div className="mx-3 mt-3">
                  {" "}
                  <p className="bold-text ">
                    Mr.Jhon deo <br></br>{" "}
                    <span className="h5 text-secondary">
                      Manager of Holidng Group
                    </span>
                  </p>
                </div>
              </div>
              <br></br>
              <br></br>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            {" "}
            <div
              className="center3 hover-underline-animation "
              style={{
                paddingLeft: "10%",
                paddingRight: "10%",
                paddingTop: "3%",
                paddingBottom: "0%",
              }}
            >
              <p className=" ">
                “Spend a lot of time talking to customers face-to-face. You’d be
                amazed how many companies don’t listen to their customers.”
                “Spend a lot of time talking to customers face-to-face. You’d be
                amazed how many companies don’t listen to their customers.”
              </p>
              <br></br>
              <div className="d-flex">
                <img src={avatar} alt="error" className="avatar" />
                <div className="mx-3 mt-3">
                  {" "}
                  <p className="bold-text ">
                    Mr.Jhon deo <br></br>{" "}
                    <span className="h5 text-secondary">
                      Manager of Holidng Group
                    </span>
                  </p>
                </div>
              </div>
              <br></br>
              <br></br>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            {" "}
            <div
              className="center3 hover-underline-animation"
              style={{
                paddingLeft: "10%",
                paddingRight: "10%",
                paddingTop: "3%",
                paddingBottom: "0%",
              }}
            >
              <p className=" ">
                “Spend a lot of time talking to customers face-to-face. You’d be
                amazed how many companies don’t listen to their customers.”
                “Spend a lot of time talking to customers face-to-face. You’d be
                amazed how many companies don’t listen to their customers.”
              </p>
              <br></br>
              <div className="d-flex">
                <img src={avatar} alt="error" className="avatar" />
                <div className="mx-3 mt-3">
                  {" "}
                  <p className="bold-text ">
                    Mr.Jhon deo <br></br>{" "}
                    <span className="h5 text-secondary">
                      Manager of Holidng Group
                    </span>
                  </p>
                </div>
              </div>
              <br></br>
              <br></br>
            </div>
          </SwiperSlide>
        </Swiper>
      </div> */}



    </>
  );
};

export default Services;
